export class Validators {
    static validateCPF(cpf: string): boolean {
        cpf = cpf.replace(/\D/g, '');
        if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
            return false;
        }
        let sum = 0;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let remainder = sum % 11;
        let digit1 = remainder < 2 ? 0 : 11 - remainder;

        sum = 0;
        for (let i = 0; i < 10; i++) {
            sum += parseInt(cpf.charAt(i)) * (11 - i);
        }
        remainder = sum % 11;
        let digit2 = remainder < 2 ? 0 : 11 - remainder;
        return parseInt(cpf.charAt(9)) === digit1 && parseInt(cpf.charAt(10)) === digit2;
    }

    static validateWhatsAppNumber(phoneNumber: string): boolean {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        if (cleanedNumber.length < 10) {
            return false;
        }
        const regex = /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/;
        console.log(regex.test(cleanedNumber))
        return regex.test(cleanedNumber);
    }

    static validateCNPJ(cnpj: string) {
        cnpj = cnpj.replace(/[^\d]/g, '');

        if (cnpj.length !== 14) {
            return false;
        }

        const firstDigitWeights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        const secondDigitWeights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

        const calculateVerificationDigit = (cnpj: string, weights: number[]) => {
            let sum = 0;
            for (let i = 0; i < weights.length; i++) {
                sum += parseInt(cnpj.charAt(i)) * weights[i];
            }
            const remainder = sum % 11;
            return remainder < 2 ? 0 : 11 - remainder;
        };

        const firstVerificationDigit = calculateVerificationDigit(cnpj.substring(0, 12), firstDigitWeights);
        const secondVerificationDigit = calculateVerificationDigit(cnpj.substring(0, 12) + firstVerificationDigit, secondDigitWeights);

        if (
            parseInt(cnpj.charAt(12)) !== firstVerificationDigit ||
            parseInt(cnpj.charAt(13)) !== secondVerificationDigit
        ) {
            return false;
        }

        return true;
    }
}
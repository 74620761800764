import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MdFullscreen } from "react-icons/md";
import { ClientService } from "service/client";
import { IMyClients } from "types/user";
import TableMyClients from "./table";


const MyClients = () => {

    const [clients, setClients] = useState<IMyClients[]>([]);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const getClients = () => {
        setLoading(true)
        ClientService.getClientsBySeller()
            .then(({ data }) => {
                setClients(data);
            }).finally(() => setLoading(false))
    }

    useEffect(() => {
        getClients();
    }, []);


    return (
        <>
            <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'}>
                <Typography variant="h2" textAlign={"center"} fontWeight={"bold"}>
                    Meus Clientes
                </Typography>
                <ul style={{
                    gap: '20px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <li>Crie seu próprio grupo no whatsapp para melhorar suas vendas diárias</li>
                    <li>Clique no nome do seu cliente para conversar via whatsapp</li>
                </ul>
                <Button
                    color="inherit"
                    variant="outlined"
                    sx={{ p: 1 }}
                    onClick={() => setOpen(true)}
                    endIcon={loading && <CircularProgress size={24} />}
                    disabled={loading}
                >
                    <MdFullscreen color="black" size={25} />
                    <Typography fontSize={16} fontWeight="500" mt={.4}>Abrir relatório</Typography>
                </Button>
            </Grid>
            <TableMyClients
                clients={clients}
                handleClose={() => {
                    setOpen(false)
                }}
                open={open}
            />
        </>
    )
}

export default MyClients;
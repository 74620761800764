import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IGerencialReport } from 'types/indications';
import { NameMask } from 'utils/mask';
import RotateComponente from 'components/rotate';

interface TabeViewProps {
    open: boolean,
    handleClose: () => void;
    gerencialReport: IGerencialReport[]
}

export default function TableGerencialReport({ open, handleClose, gerencialReport }: TabeViewProps) {

    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0 } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
                // overflow: 'hidden'
            }}>
                <RotateComponente>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead sx={{ bgcolor: "#316FE8" }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white' }}>NOME</TableCell>
                                    <TableCell sx={{ color: 'white' }}>WHATSAPP</TableCell>
                                    <TableCell sx={{ color: 'white' }}>CIDADE</TableCell>
                                    <TableCell sx={{ color: 'white' }}>UF</TableCell>
                                    <TableCell sx={{ color: 'white' }}>VENDAS</TableCell>
                                    <TableCell sx={{ color: 'white' }}>MINHA COMISSÃO %</TableCell>
                                    <TableCell sx={{ color: 'white' }}>MINHA COMISSÃO R$</TableCell>
                                    <TableCell sx={{ color: 'white' }}>GRAU DE INDICAÇÃO</TableCell>
                                    <TableCell sx={{ color: 'white' }}>INDICAÇÃO DIRETA DE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gerencialReport?.map((row, index) => {
                                    const color = index % 2 === 0 ? '#316FE840' : '#316FE824'
                                    const NameSeller = NameMask(row?.name ?? "")
                                    const nameDirectFrom = NameMask(row?.directFrom ?? "")
                                    return (
                                        <TableRow

                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: color }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {NameSeller.firstName} {NameSeller.lastName}
                                            </TableCell>
                                            <TableCell>{row?.personalData?.phone}</TableCell>
                                            <TableCell>{row?.personalData?.city}</TableCell>
                                            <TableCell>{row?.personalData?.uf}</TableCell>
                                            <TableCell>{row?.totalSalled?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                            <TableCell>{row?.percentage * 100}%</TableCell>
                                            <TableCell>{row?.commission?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                            <TableCell align="center">
                                                {row?.level === 1 && "Indicação direta"}
                                                {row?.level === 2 && "Indicação indireta 1° Grau"}
                                                {row?.level === 3 && "Indicação indireta 2° Grau"}
                                                {row?.level === 4 && "Indicação indireta 3° Grau"}
                                                {row?.level === 5 && "Indicação indireta 4° Grau"}
                                            </TableCell>
                                            <TableCell>{nameDirectFrom.firstName} {nameDirectFrom.lastName}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>

                </Button> */}
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import { Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import TextMarked from "components/markedText";
import useAuth from "context/auth";
import useBet, { NumbersGroups } from "context/bet";
import { MdRemoveRedEye } from "react-icons/md";
import { moneyMask } from "utils/mask";

interface BuyPredictionCardProps {
    color?: "primary" | "secondary" | "success" | "error",
    value: {
        value: number,
        percent: number
    };
    amount: NumbersGroups;
    fakeValue: number;
    onBuy: (group: NumbersGroups, value: number) => void;
}

const BuyPredictionCard = (props: BuyPredictionCardProps) => {

    const { palette } = useTheme();

    const { purchaseFlow, showComissionSellerPurchase } = useBet();

    const { color, amount, value, fakeValue, onBuy } = props;
    const mainColor = color ? palette[color] : palette.success

    return (
        <Grid
            position="relative"
            border={`3px solid ${mainColor.main}`}
            borderRadius={2}
            width="100%">
            <Grid container p={2} bgcolor={mainColor.light}>
                <Grid item xs={5.8} container justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
                    <TextMarked color={color} variant="h2">{amount}</TextMarked>
                    <Typography variant="h4" fontWeight="500" textAlign={"center"}>
                        apostas de <br />QUADRA
                    </Typography>
                </Grid>
                <Divider sx={{ borderColor: mainColor.main }} variant="middle" orientation="vertical" flexItem />
                <Grid item xs={6} container justifyContent={"center"} alignItems="center" flexDirection={'column'}>
                    <Grid container alignItems="center" justifyContent="center" gap={1} >
                        {
                            amount > 5 && <>
                                <Typography mt={2} variant="h4" fontWeight="500" textAlign="center" >
                                    De
                                </Typography>
                                <Typography
                                    mt={2}
                                    variant="h4" fontWeight="500" textAlign="center"
                                    position="relative"
                                    sx={{
                                        ":after": {
                                            content: "''",
                                            position: "absolute",
                                            width: "100%",
                                            border: "1px solid rgba(255, 3, 3, .5)",
                                            left: 0,
                                            top: "50%",
                                            transform: 'translateY(-50%)'
                                        }
                                    }}
                                >
                                    {moneyMask(String(fakeValue.toFixed(2)))}
                                </Typography>
                            </>
                        }
                    </Grid>

                    {value.percent > 0 &&
                        <Grid>
                            <Typography color="red" fontWeight={"500"}>{value.percent * 100}% de Desconto</Typography>
                        </Grid>
                    }

                    <Grid p={1}>
                        <Typography variant="h4" fontWeight="500" textAlign="center">
                            Por apenas
                        </Typography>
                        <Typography variant="h2" fontWeight="bold" textAlign="center" >
                            {moneyMask(String(value.value.toFixed(2)))}
                        </Typography>
                    </Grid>
                    {
                        purchaseFlow === 'saller' &&
                        <>
                            {
                                showComissionSellerPurchase ?
                                    <>
                                        <Typography variant="h5" fontWeight="500" textAlign="center" color={palette.grey[600]}>
                                            Comissão padrão:
                                        </Typography>
                                        <Typography
                                            color={palette.grey[600]}
                                            variant="h3" fontWeight="bold" textAlign="center">
                                            {moneyMask(String((value.value * 0.3).toFixed(2)))}
                                        </Typography>
                                    </> : <></>
                            }
                        </>
                    }
                </Grid>
            </Grid>
            <Divider sx={{ borderBottomColor: mainColor.main }} variant="middle" />
            <Grid container overflow={"hidden"} borderRadius={2}>
                <Grid p={2} py={4} pb={8} container bgcolor="white" flexDirection={"column"} gap={2} alignItems={"center"}>
                    <Typography variant="h4" fontWeight="500" textAlign="center">
                        Compre <TextMarked color={color} variant="h4">{amount} apostas</TextMarked> de QUADRA
                        <br /> <span style={{ borderBottom: '1px solid #767676' }}> e concorra gratuitamente a</span>:
                    </Typography>
                    <Grid>
                        <Typography variant="h4" fontWeight="500" textAlign="start">
                            <TextMarked color={color} variant="h4">{amount}</TextMarked> <span> TERNOS <i style={{
                                color: "#80808078",
                                fontSize: "14px"
                            }}>cortesia</i></span>
                        </Typography>
                        <Typography variant="h4" fontWeight="500" textAlign="start">
                            <TextMarked color={color} variant="h4">{amount}</TextMarked> <span> DUQUES <i style={{
                                color: "#80808078",
                                fontSize: "14px"
                            }}>cortesia</i></span>
                        </Typography>
                        <Typography variant="h4" fontWeight="500" textAlign="start">
                            <TextMarked color={color} variant="h4">{amount}</TextMarked> <span> UNIDADES <i style={{
                                color: "#80808078",
                                fontSize: "14px"
                            }}>cortesia</i></span>
                        </Typography>
                    </Grid>
                    <Typography variant="h3" fontWeight="700" textAlign={"center"} paddingLeft={amount === 20 ? 2 : 0}>
                        São {amount * 4} chances de ganhar
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 50%)',
                    minWidth: 200,
                    py: 1
                }}
            >
                <Button
                    className="pulse"
                    onClick={() => onBuy(amount, value.value)}
                    color={color}
                    variant="contained"
                    size="medium"
                    sx={{
                        py: 1,
                        minWidth: 200
                    }}>
                    <Typography variant="h4" fontWeight="700" color="white">
                        {amount === 20 ? "COMPRE AGORA!" : "COMPRE"}
                        <br />
                        {amount} apostas
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default BuyPredictionCard;
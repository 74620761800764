import { Navigate, RouteObject, useLocation } from "react-router-dom";
import ClientApp from "pages/client";
import ClientAppLayout from "layout/clients";
import RoutesPath from "utils/routesBases";
import WithdrawPage from "components/withdraw";
import AuthLayout from "layout/auth";
import useAuth from "context/auth";

const ClientRouters = (): RouteObject[] => {
    const { tokenClient } = useAuth();
    const { search } = useLocation()
    if (!tokenClient) {
        return [
            {
                element: <Navigate to={RoutesPath.Clients.login.path + search} />,
                path: RoutesPath.Clients.pathRedirect,
            },
            {
                element: <Navigate to={RoutesPath.Clients.login.path + search} />,
                path: RoutesPath.Clients.pathRedirectacesso,
            },
            {
                element: <Navigate to={RoutesPath.Clients.login.path + search} />,
                path: RoutesPath.Clients.path,
            },
            {
                element: <AuthLayout />,
                path: RoutesPath.Clients.path,
                children: [
                    {
                        element: RoutesPath.Clients.login.element,
                        path: RoutesPath.Clients.login.path,
                    }
                ]
            }
        ]
    }
    return [
        {
            element: <ClientAppLayout />,
            path: "/cliente",
            children: [
                {
                    element: <Navigate to={RoutesPath.Clients.path} />,
                    path: RoutesPath.Clients.login.path,
                },
                {
                    element: <Navigate to={RoutesPath.Clients.path} />,
                    path: "/cliente/*",
                },
                {
                    element: <Navigate to={RoutesPath.Clients.path} />,
                    path: RoutesPath.Clients.pathRedirect,
                },
                {
                    element: RoutesPath.Clients.element,
                    path: RoutesPath.Clients.path,
                },
                {
                    element: RoutesPath.Clients.bilhetes.element,
                    path: RoutesPath.Clients.bilhetes.path + '/:id',
                },
                {
                    element: RoutesPath.Clients.saque.element,
                    path: RoutesPath.Clients.saque.path,
                },
                {
                    element: RoutesPath.Clients.deposito.element,
                    path: RoutesPath.Clients.deposito.path
                }
            ]
        }
    ]
}

export default ClientRouters;
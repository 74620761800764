import { Box, Button, Container, Grid, Typography } from "@mui/material"
import SVGLOGO from "assets/imgs/logo 01.png"
import useBet from "context/bet";
import { Outlet, useNavigate } from "react-router-dom"

const AuthLayout = () => {

    const { prizesDraws, seller, selectDraw, clientThatIndicate } = useBet();
    const navigate = useNavigate();

    return (
        <Container maxWidth="xs">
            <Grid height='calc(100vh - 1rem)' p={2} justifyContent='center' container alignContent='flex-start' >
                <Box>
                    <Grid container flexDirection='column' alignItems={'center'}>
                        <img src={SVGLOGO} width={100} />
                        <Button 
                         onClick={() => navigate('/quem-somos' + `?s=${seller?.uuid}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}`)}
                        sx={{  textDecoration: 'underline' }}>Quem somos</Button>
                    </Grid>
                </Box>
                <Box width={'100%'}>
                    <Grid width={"100%"}>
                        <Outlet />
                    </Grid>
                </Box>
            </Grid>
        </Container>
    )
}
export default AuthLayout;
import { Card, Divider, Grid, Typography, useMediaQuery } from "@mui/material"
import useBet from "context/bet";
import StampBlue12 from "assets/imgs/stamps/hours/AZUL 12H.png"
import StampBlue18 from "assets/imgs/stamps/hours/AZUL 18H.png"
import StampGreen12 from "assets/imgs/stamps/hours/VERDE 12H.png"
import StampGreen18 from "assets/imgs/stamps/hours/VERDE 18H.png"
import StampOrange12 from "assets/imgs/stamps/hours/LARANJA 12H.png"
import StampOrange18 from "assets/imgs/stamps/hours/LARANJA 18H.png"


interface CardDrawProps {
    day: string;
    color: {
        color: string,
        img: string
    };
    selected: number | null;
    onChange: (id: number | null) => void;
    id: number | null;
    date: string;
    hors: string;
    prizeDrawFrom?: 0 | 1;
    prizes: {
        id: number,
        prizeDrawId: number,
        value: number,
        order: number,
        legibleName: string
    }[],
    numberPrice: number,
    index: number,
    dayNumber: number
}

const CardDraw = (props: CardDrawProps) => {

    const { day, color, onChange, selected, id, date, hors: hours, prizeDrawFrom, prizes, numberPrice, dayNumber } = props;

    const { hoursToShowStamp, purchaseFlow } = useBet()

    const isSelected = selected === id

    const totalPrizes = prizes.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)

    const matchMobile = useMediaQuery('(min-width:448px)')

    const getStampHours = (): string => {
        switch (dayNumber) {
            case 0: // Domingo
            case 2: // Terça
            case 4: // Quinta
                return hours === "12:00" ? StampGreen12 : StampGreen18
            case 1: // Segunda
            case 3: // Quarta
            case 5: // Sexta
                return hours === "12:00" ? StampBlue12 : StampBlue18
            case 6: // Sábado
                return hours === "12:00" ? StampOrange12 : StampOrange18
            default:
                return hours === "12:00" ? StampGreen12 : StampGreen18
        }
    }

    const showStampHours = (): boolean => {
        console.log(dayNumber)
        const isValid = ["12:00", "18:00"]

        if (isValid.includes(hours)) {
            return true
        }

        return false
    }

    return (
        <Grid container position={"relative"} sx={{
            ... (!selected && {
                animation: `pulseWithoutBoxShadow 2s infinite`
            })
        }}>
            <Card
                onClick={() => onChange(id ?? null)}
                variant="outlined"
                sx={{
                    width: '100%',
                    borderRadius: 3,
                    borderColor: color.color,
                    bgcolor: isSelected ? color.color : 'transparent',
                    cursor: 'pointer',
                    position: 'relative',
                    borderWidth: 2,

                }}>
                <Grid sx={{ p: 1 }}>
                    <Typography variant="h4" textAlign='center' fontWeight="400" color={isSelected ? "white" : color.color}>
                        Concurso de {!matchMobile && <br />}<strong>{day}</strong>
                    </Typography>
                    <Typography variant="h4" textAlign='center' fontWeight="400" color={isSelected ? "white" : color.color}>
                        <strong>  {date}</strong> às <strong>{hours} horas</strong>
                    </Typography>
                    <Typography variant="h4" textAlign='center' fontWeight="400" color={isSelected ? "white" : color.color}>
                        Total em<strong> PRÊMIOS</strong>: <strong>{totalPrizes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                    </Typography>
                    <Typography variant="h4" textAlign='center' fontWeight="400" color={isSelected ? "white" : color.color}>
                        {prizeDrawFrom !== null && prizeDrawFrom === 1 ? `Sorteio` : `Sorteio Exclusivo`}: {prizeDrawFrom !== null ? prizeDrawFrom === 1 ? 'Loteria Federal' : 'Número Favorito' : day.toUpperCase() === 'QUARTA-FEIRA' || day.toUpperCase() === 'SÁBADO' ? 'Loteria Federal' : 'Número Favorito'}
                    </Typography>
                    <Typography variant="h4" textAlign='center' fontWeight="400" color={isSelected ? "white" : color.color}>
                        Valor de cada aposta: <strong>{numberPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                    </Typography>
                    {
                        isSelected &&
                        <Grid>
                            <Divider style={{ borderWidth: 'thin', marginTop: 10, marginBottom: 10, borderColor: '#FFFFFF90' }} />
                            <Typography variant="h4" textAlign='center' fontWeight="400" color={"#FFFFFF"} pr={hours === hoursToShowStamp ? 5 : 0}>
                                Veja acima os prêmios deste concurso
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Card>
            {showStampHours() && <img
                src={getStampHours()}
                style={{
                    position: 'absolute',
                    left: purchaseFlow === "client" ? matchMobile ? -10 : -25 : -40,
                    top: purchaseFlow === "client" ? matchMobile ? -10 : -25 : -20,
                    cursor: "pointer",
                    width: "80px",
                    transform: 'rotate(-15deg)',
                }}
            />}
        </Grid>
    )
}

export default CardDraw;
import StorageService from "service/storage"
import api from "../api"
import axios from "axios"
import { Utils } from "utils"

export class PrizeDrawsService {
    static get() {
        return api.get('prize-draws/open')
    }
    static commissionReport(){
        return api.get('prize-draws/commission-report')
        
    }

    static results(){
        return api.get('prize-draws/result')
    }

    static resultsclients(){
        return api.get('clients/results')
    }

    static prizes(){
        return api.get('clients/prizes')
    }

    static gerencialReport(prize_draw_id: number) {
        return api.get('prize-draws/gerencial-report' + (prize_draw_id > 0 ? `?prize-draw-id=${prize_draw_id}` : ""))
    }

    static async getVideos(token: string) {
        return axios.get(Utils.URLSBases.apisNFV + '/prize-draws/video', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Dialog, Grid, Typography } from '@mui/material';
import { TbAlertTriangleFilled } from "react-icons/tb";
import { useLocation, useNavigate } from 'react-router-dom';
import useBet from 'context/bet';
import RoutesPath from 'utils/routesBases';

const CountdownTimer: React.FC = () => {

    const { ResetBet, purchaseFlow, sellerUuid, clientThatIndicate } = useBet();

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const [showAlertTime, setShowAlertTime] = useState(false)
    const [timeLeft, setTimeLeft] = useState(moment.duration(10, 'minutes'));

    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Correspondente

    useEffect(() => {
        if (!pathname.includes("/recibo")) {
            const interval = setInterval(() => {
                setTimeLeft(prevTime => {
                    const newTime = moment.duration(prevTime.asMilliseconds() - 1000, 'milliseconds');
                    if (newTime.asMilliseconds() <= 0) {
                        clearInterval(interval);
                        ResetBet();
                        navigate(RouterPath.bet.home.path + (sellerUuid ? `?s=${sellerUuid}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}` : ''))
                        return moment.duration(0, 'milliseconds');
                    }
                    if (newTime.minutes() === 1 && newTime.seconds() === 0) {
                        setShowAlertTime(true);
                    }
                    return newTime;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, []);

    const formatTime = (value: number) => {
        return value.toString().padStart(2, '0');
    };


    useEffect(() => {
        if (!pathname.includes("/recibo")) {
            StartCount()
        }
    }, [])

    const StartCount = () => {
        setTimeLeft(moment.duration(10, 'minutes'))
    }

    return (
        <>
            {!pathname.includes("/recibo") && <Typography color="#848484" textAlign="center" mt={1}>
                Tempo restante: {`${timeLeft.minutes()}:${formatTime(timeLeft.seconds())}`}
            </Typography>}
            <Dialog
                open={showAlertTime && !pathname.includes("/recibo")}
                PaperProps={{ sx: { p: 0, bgcolor: 'transparent' } }}>
                <Grid maxWidth={300} overflow="hidden" borderRadius={2}>
                    <Grid p={2} bgcolor="red" container justifyContent="center">
                        <TbAlertTriangleFilled color="white" size={50} />
                    </Grid>
                    <Grid
                        p={2}
                        bgcolor="white"
                        container
                        alignItems="center"
                        justifyContent="center"
                        flexDirection={'column'}
                        gap={2}>
                        <Typography variant="h3" textAlign="center">
                            Tempo restante para pagar o pedido:
                        </Typography>
                        <Typography variant="h1" color="red" textAlign="center">
                            {`${timeLeft.minutes()}:${formatTime(timeLeft.seconds())}`}
                        </Typography>
                        <Button
                            onClick={() => { setShowAlertTime(false) }}
                            variant="contained"
                            color="secondary"
                            fullWidth
                            sx={{ maxWidth: 180 }}>
                            Entendi
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export default CountdownTimer;

import { useTheme } from "@mui/material";

export const PredictionInput: React.FC<{
  disable?: boolean;
  value: number | undefined;
  onChange: (value: number | null) => void;
  inputRef?: (el: any) => void,
  onFocusIn?: () => void;
  onFocusOut?: () => void;
  onDelete: () => void;
  onKeyDown: (value: any) => void;
  isFavorite?: boolean,
  isFocus?: boolean,
  index: number
}> = ({ value, onChange, inputRef, disable, onFocusIn, onFocusOut, isFavorite, onDelete, onKeyDown, isFocus, index, ...rest }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value.replace(/\D/g, '')?.substring(0, 1));
    onChange(event.target.value === "" ? null : newValue);
  };

  const { palette } = useTheme()

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      {
        disable &&
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 10,
        }} />
      }

      <input
        {...rest}
        ref={inputRef}
        typeof="number"
        inputMode='numeric'
        maxLength={1}
        id="input_number_fav"
        value={value ?? ''}
        type="number"
        onChange={handleChange}
        className="input_number_fav"
        onFocus={onFocusIn}
        onBlur={onFocusOut}
        onKeyDown={(e) => {
          onKeyDown(e.key)
          if (e.key === "Backspace" || e.keyCode === 8) {
            onDelete()
          }
        }}
        style={{
          ...(index === 0 && {
            color: 'rgba(0, 82, 0, .9)',
          }),
          ...(index === 1 && {
            color: 'rgba(0, 82, 0, .8)',
          }),
          ...(index === 2 && {
            color: 'rgba(0, 82, 0, .7)',
          }),
          ...(isFavorite && {
            color: palette.primary.main,
            textDecoration: 'underline',
            textDecorationColor: '#bab7b6'
          }),
          ... (isFocus && {
            transform: 'scale(1.1)',
            transition: 'transform .3s ease',
            // border: '0px 1px 0px 1px solid GrayText'
          })
        }}
      />
    </div>
  );
};
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, TableCell, TableRow, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import RotateComponente from 'components/rotate';
import TableView from 'components/table';
import { ComissionReportProps, PrizesProps, ResultsPrizesProps, prizeDrawsProps } from 'types';
import moment from 'moment';
import { NameMask } from 'utils/mask';

interface TablePrizesProps {
    open: boolean,
    handleClose: () => void;
    prizes: PrizesProps[] | null;
}

export default function TablePrizes({ open, handleClose, prizes }: TablePrizesProps) {

    const theme = useTheme();
    const prize = ['Quadra', 'Prêmio de TERNO - Cortesia por aproximação', 'Prêmio de DUQUE - Cortesia por aproximação', 'Prêmio de UNIDADE - Cortesia por aproximação']

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0 } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
                // overflow: 'hidden'
            }}>
                <RotateComponente>
                    <TableView
                        align='center'
                        header={['Concurso', 'Prêmio', 'Valor bruto do prêmio', 'Quantidade de ganhadores', 'Minha quota parte do prêmio', 'Meu prêmio']}
                    >
                        <TableBody>
                            {
                                prizes?.map(({
                                    prizeDrawAt,
                                    prizeDrawId,
                                    prizeName,
                                    prizeValue,
                                    winners
                                }, _index) => {
                                    const perPerson = prizeValue / Number(winners)
                                    return (
                                        <TableRow key={`${prizeDrawId}-${_index}`}>
                                            <TableCell align="center">
                                                {prizeDrawId}, {String(moment(prizeDrawAt).format("dddd")).toUpperCase()} <br />
                                                {String(moment(prizeDrawAt).format("L")).toUpperCase()} às {String(moment(prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                                            </TableCell>
                                            <TableCell align="center">
                                                {prize.find((name) => name.toLowerCase().includes(prizeName.toLowerCase())) ?? prizeName}
                                            </TableCell>
                                            <TableCell align="center">
                                                {prizeValue === null ? '-' : prizeValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                            <TableCell align="center">
                                                {winners}
                                            </TableCell>
                                            <TableCell align="center">
                                                {prizeValue === null ? '-' : `${((perPerson / prizeValue) * 100).toFixed(2)}%`}
                                            </TableCell>
                                            <TableCell align="center">
                                                {prizeValue === null ? '2 Apostas de cortesia' : perPerson?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </TableView>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>

                </Button> */}
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, Typography, useTheme } from '@mui/material';
import { PredictionInput } from 'components/prediction/input';
import { NumberInput } from 'components/input/numberInput';
import { AuthService } from 'service/auth';
import useAuth from 'context/auth';
import { toast } from 'react-toastify';


interface PinProps {
    0: number | null,
    1: number | null,
    2: number | null
}

interface AlertAwaitIdentityProps {
    open: boolean,
    error: boolean;
    userId: number | null;
}

export default function AlertAwaitIdentity({ open, userId }: AlertAwaitIdentityProps) {
    const { palette } = useTheme();

    const { Authenticate } = useAuth();

    const inputsRef = React.useRef<(HTMLInputElement | null)[]>([]);

    const [loading, setLoading] = React.useState(false);

    const [PinValues, setPinValues] = React.useState<PinProps>({
        0: null,
        1: null,
        2: null
    })

    React.useEffect(() => {
        if (userId) {
            if (PinValues[0] && PinValues[1] && PinValues[2]) {
                setLoading(true)
                AuthService.confirmIdentity(userId, String(PinValues[0]) + String(PinValues[1]) + String(PinValues[2]))
                    .then(({ data }) => {
                        Authenticate(data, data?.role === 20 ? 'general' : 'seller')
                    })
                    .catch((err) => {
                        toast(err?.response?.data?.message ?? "Error ao enviar token", { type: "error" })
                        setPinValues({
                            0: null,
                            1: null,
                            2: null
                        })
                    })
                    .finally(() => setLoading(false))
            }
        }
    }, [PinValues])

    return (
        <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Typography variant="h4" textAlign="center">
                Enviamos um novo código de segurança de 3 dígitos para seu <strong>SMS</strong> e <strong>Whatsapp</strong>.
                <br/>
                Digite o código abaixo para <strong>continuar</strong>.
                </Typography>
                <Grid container flexDirection={"row"} flexWrap={"nowrap"} gap={1} mt={2}>
                    {
                        Array(3).fill(null).map((_, index) => (
                            <Grid bgcolor={palette.grey[300]} p={2}>
                                <NumberInput
                                    disabled={loading}
                                    onFocus={event => event.target.select()}
                                    inputRef={(el) => inputsRef.current[index] = el}
                                    value={PinValues[index as 0 | 1 | 2] ?? undefined}
                                    onChange={(value) => {
                                        if (!isNaN(Number(value.target.value)) && index < 2) {
                                            inputsRef.current[index + 1]?.focus();
                                        }
                                    }}
                                    onChangeNumber={(value) => {
                                        setPinValues({
                                            ...PinValues,
                                            [index]: value
                                        })
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
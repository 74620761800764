import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material"
import SVGLOGO from "assets/imgs/logo.svg"

const TopBar = () => {
    
    return (
        <Grid p={2} width={"100%"}>
            <Grid container justifyContent={'space-between'}>
                <img src={SVGLOGO} width={80} />
                <Box>
                    <Grid container flexDirection={'column'} alignItems={'flex-end'}>
                        <Typography textAlign={'end'} fontWeight="bold" color="GrayText">
                            Correspondente
                        </Typography>
                        <Typography color="GrayText">
                            Nome completo do correspondente
                        </Typography>
                        <Button color="inherit" sx={{ ml: 1, textDecoration: 'underline', fontSize: 10, color: 'GrayText' }}>
                            (00) 0 0000-0000
                        </Button>
                    </Grid>
                </Box>
            </Grid>
            <Stack direction="row" spacing={1} mt={2}>
                <ChipItem
                    label="Menu"
                    onClick={() => {}}
                />
                <ChipItem
                    label="Quem somos"
                    onClick={() => {}}
                />
                <ChipItem
                    label="Regulamento"
                    onClick={() => {}}
                />
               <ChipItem
                    label="Aprenda"
                    onClick={() => {}}
                />
            </Stack>
        </Grid>
    )
}

export default TopBar;

interface chipItemProps {
    label: string;
    onClick: () => void;
}

const ChipItem = ({ label, onClick }: chipItemProps) => (
    <Chip
    label={label}
    color="secondary"
    sx={{ bgcolor: '#316FE81A', color: '#234077', fontWeight: 'bold' }}
    onClick={onClick}
/>
)
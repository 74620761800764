import { Box, Button, Chip, Container, Grid, Stack, Typography, Slide, useTheme, useMediaQuery } from "@mui/material"

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import SVGLOGO from "assets/imgs/logo 01.png"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import useBet from "context/bet";
import { NameMask, phoneMask } from "utils/mask";
import { useEffect, useRef, useState } from "react";
import CountdownTimer from "components/countdownTimer";
import { ClientMenu } from "./saller/menu";
import RoutesPath from "utils/routesBases";
import useQuery from "hooks/useQuery";
import useAuth from "context/auth";
import { constants } from "utils";
import theme from "theme";
import { FaWhatsapp } from "react-icons/fa6";
import { MdLiveTv } from "react-icons/md";

const BetLayout = () => {

    const navigate = useNavigate();
    const { palette } = useTheme();
    const { user } = useAuth();

    const breackpoint = !useMediaQuery("(min-width:331px)")

    const query = useQuery();

    const [menu, setMenu] = useState(false);

    const { pathname, search } = useLocation();

    const { prizesDraws, seller, selectDraw, purchaseFlow } = useBet();

    const [PlayCarrouselBanner, setPlayCarrouselBanner] = useState(true);
    const swiperRefLocal = useRef<SwiperRef | null>(null);

    const onPlayCarrousel = () => {
        setPlayCarrouselBanner(!PlayCarrouselBanner)
    };

    useEffect(() => {
        if (PlayCarrouselBanner) {
            swiperRefLocal?.current?.swiper?.autoplay?.start()
        } else {
            swiperRefLocal?.current?.swiper?.autoplay?.stop()
        }
    }, [PlayCarrouselBanner, swiperRefLocal])

    const HiddenCarrosel = pathname.includes('recibo') ? true : false

    const verifyHOME = seller?.id === 708 || seller?.id === 755


    const NameSaleer = NameMask((verifyHOME ? "" : seller?.name) ?? "")

    const _sallerquery = query.get('s')
    const clientIndicate = query.get('c')

    return (
        <Container sx={{ p: 0 }} maxWidth="sm">
            <Grid width={"100%"}>
                <Grid p={2} container justifyContent={'space-between'}>
                    <Grid display={"flex"} flexDirection={"column"}>
                        <Link to={"/quemsomos" + search}>
                            <img src={SVGLOGO} width={80} height={40} />
                        </Link>
                        {(purchaseFlow === 'client' && user && !verifyHOME) && <div>
                            <Link to={user?.credits >= constants.minPixTransfer ? "/cliente/saque" : (window.location.pathname + window.location.search)} style={{
                                textDecoration: 'none'
                            }}>
                                <Typography fontWeight={"400"} textAlign={"left"} color={"GrayText"}>
                                    Saldo Disponível: <span style={{
                                        color: theme().palette.primary.main,
                                        fontWeight: 'bold'
                                    }}>{user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                </Typography>
                            </Link>
                            <Typography fontWeight={"400"} textAlign={"left"} color={"GrayText"}>Bônus de aposta: <span style={{
                                color: theme().palette.secondary.main,
                                fontWeight: 'bold'
                            }}>{(user?.guessCredits ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                            </Typography>
                        </div>}
                    </Grid>
                    {(purchaseFlow === 'client' && user && verifyHOME) && <div>
                        <Link to={user?.credits >= constants.minPixTransfer ? "/cliente/saque" : "#"} style={{
                            textDecoration: 'none'
                        }}>
                            <Typography fontWeight={"400"} textAlign={"center"} color={"GrayText"}>
                                Saldo Disponível:
                            </Typography>
                            <Typography fontWeight="bold" color={palette.primary.main} textAlign={"center"}>
                                {user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                        </Link>
                        <Typography fontWeight={"400"} textAlign={"center"} color={"GrayText"}>Bônus de aposta:
                        </Typography>
                        <Typography fontWeight="bold" color={palette.secondary.main} textAlign={"center"}>
                            {(user?.guessCredits ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                    </div>}
                    {!verifyHOME && !pathname.includes("apostar") && <Box mt={breackpoint ? 2 : 0}>
                        <Grid container flexDirection={'column'} alignItems={breackpoint ? 'flex-start' : 'flex-end'}>
                            <Typography textAlign={'end'} fontWeight="bold" color="GrayText">
                                {verifyHOME ? "" : "Correspondente"}
                            </Typography>
                            <Typography color="GrayText" >
                                {NameSaleer.firstName} {NameSaleer.lastName}
                            </Typography>
                            {
                                verifyHOME ? <></> :
                                    <Button
                                        component="a"
                                        target="_blank"
                                        href={"https://api.whatsapp.com/send/?phone=" + '55' + ((seller?.phone ?? "00000000000"))}
                                        color="inherit"
                                        sx={{ ml: breackpoint ? -1 : 0, textDecoration: 'underline', fontSize: 10, color: 'GrayText' }}>
                                        {phoneMask((seller?.phone ?? "00000000000"))}
                                    </Button>
                            }

                        </Grid>
                    </Box>}
                </Grid>
                <Stack direction="row" spacing={1} my={2} px={1} overflow={"auto"} flexWrap={"wrap"} gap={1}>
                    <ChipItem
                        label="Menu"
                        onClick={() => purchaseFlow === 'client' ? navigate('/cliente' + search) : navigate('/correspondente')}
                    // onClick={() => navigate('/' + `?s=${seller?.uuid}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}`)}
                    />
                    <ChipItem
                        label="Quem somos"
                        onClick={() => {
                            navigate('/quemsomos' + `?s=${_sallerquery ?? ""}${clientIndicate ? `&c=${clientIndicate}` : ''}`)
                        }}
                    />
                    <ChipItem
                        label="Regulamento"
                        onClick={() => navigate('/regulamento' + `?s=${_sallerquery}${clientIndicate ? `&c=${clientIndicate}` : ''}`, {
                            state: {
                                prev_path: window.location.pathname
                            }
                        })}
                    />
                    {/* <ChipItem
                        label="Aprenda"
                        onClick={() => navigate(RoutesPath.aprenda)}
                    /> */}
                    <ChipItem
                        // icon={<FaWhatsapp color="green" size={20} style={{ marginLeft: '10px' }} />}
                        label="Atendimento"
                        onClick={() => {
                            window.open("https://wa.me/+5541991816829", "_blank")
                        }}
                    />
                    <ChipItem
                        icon={<MdLiveTv color={palette.primary.main} size={16} style={{ marginLeft: '10px', marginBottom: '3px' }} />}
                        label="Assistir sorteios"
                        onClick={() => navigate(RoutesPath.sorteio.path, {
                            state: {
                                prev_path: window.location.pathname
                            }
                        })}
                    />
                    <ChipItem
                        pulse
                        icon={<FaWhatsapp color="green" size={20} style={{ marginLeft: '10px' }} />}
                        label="Participe do grupo Número Favorito"
                        onClick={() => {
                            window.open("https://chat.whatsapp.com/JgXQM7gnO6tJBcXFG64gbo", "_blank")
                        }}
                    />
                </Stack>
                <Swiper
                    spaceBetween={0}
                    ref={swiperRefLocal}
                    slidesPerView={1}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 5000,
                        pauseOnMouseEnter: true
                    }}
                >
                    {
                        !selectDraw ? prizesDraws?.map((prizes) => (
                            !HiddenCarrosel ? <SwiperSlide
                                onClick={onPlayCarrousel}

                                key={prizes.id}>
                                <img src={prizes.banner} width="100%" alt="Banner" />
                            </SwiperSlide>
                                : <></>

                        ))
                            :
                            <img src={selectDraw.banner} width="100%" alt="Banner" />
                    }
                </Swiper>
                <CountdownTimer />
                <Outlet />
            </Grid>
            <ClientMenu
                open={menu}
                purchase="client"
                handleClose={() => { setMenu(false) }}
            />
        </Container>
    )
}

export default BetLayout;

interface chipItemProps {
    label: string;
    onClick: () => void;
    icon?: JSX.Element,
    pulse?: boolean
}

export const ChipItem = ({ label, onClick, icon, pulse }: chipItemProps) => (
    <Chip
        clickable
        label={(
            <section>
              <div style={{
                 whiteSpace: 'normal',
              }}>{label}</div>
            </section>
          )}
        icon={icon}
        color="default"
        sx={{
            bgcolor: '#316FE81A', color: '#234077', fontWeight: 'bold', ':focus': {
                bgcolor: '#316FE81A', color: '#234077',
            },
            height: 'auto',
            padding: '5px 3px',
        }}
        className={`${pulse ? 'pulse' : ''}`}
        onClick={onClick}
    />
)
import { Box, Button, Container, Grid, IconButton, Typography, useTheme } from "@mui/material"
import SVGLOGO from "assets/imgs/logo 01.png"
import { IoMenu } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import SwipeableTemporaryDrawer from "./menu";
import { useState } from "react";
import useAuth from "context/auth";
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import useBet from "context/bet";
import { ChipItem } from "layout/bet";
import { FaWhatsapp } from "react-icons/fa6";
import { Roles } from "utils";

const AppLayout = () => {

    const { prizesDraws, seller, selectDraw, clientThatIndicate } = useBet();

    const navigate = useNavigate();

    const { search } = useLocation();

    const { user } = useAuth();

    const { pathname } = useLocation();
    const theme = useTheme()

    const [menu, setMenu] = useState(false);

    return (
        <Container maxWidth="xs" sx={{ p: 0 }}>
            <Grid justifyContent='center' container alignContent='flex-start'>
                <Grid container justifyContent='space-between' alignContent='center' p={2} flexWrap={"nowrap"}>
                    <div>
                        <Grid container flexDirection='column' alignItems={'center'}>
                            <Link to={"/quemsomos" + search}>
                                <img src={SVGLOGO} width={100} />
                            </Link>

                            <Button
                                onClick={() => navigate('/' + `?s=${seller?.uuid}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}`)}
                                sx={{ textDecoration: 'underline' }}>Quem somos</Button>
                        </Grid>
                    </div>
                    <Grid display={"flex"} flexDirection={"column"} gap={1} justifyContent={"center"}>
                        {user?.role === Roles.seller &&
                            <Typography variant='h5' textAlign="center" color={theme.palette.grey[700]}>
                                <strong>  {user?.personalData?.city} </strong><br /> e região
                            </Typography>}
                        <Grid display={"flex"} flexWrap={"wrap"} gap={1} justifyContent={"center"} sx={{
                            maxWidth: 200
                        }}>
                            <ChipItem
                                label="Atendimento"
                                onClick={() => {
                                    window.open("https://wa.me/+5541991816829", "_blank")
                                }}
                            />
                            <ChipItem
                                pulse
                                icon={<FaWhatsapp color="green" size={20} style={{ marginLeft: '10px' }} />}
                                label="Participe do grupo Número Favorito"
                                onClick={() => {
                                    window.open("https://chat.whatsapp.com/JgXQM7gnO6tJBcXFG64gbo", "_blank")
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <Grid container flexDirection='column'>
                            <IconButton onClick={() => setMenu(true)}>
                                <IoMenu color="black" />
                            </IconButton>
                            <Typography variant='h6' textAlign="center" fontWeight="bold" mt={-1}>
                                Menu
                            </Typography>
                        </Grid>
                    </div>
                </Grid>
                <Box width={'100%'}>
                    <Grid width={"100%"} p={0}>
                        {
                            pathname.includes('venda') &&
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                            >
                                {
                                    !selectDraw ? prizesDraws?.map((prizes) => (
                                        <SwiperSlide key={prizes.id}>
                                            <img src={prizes.banner} width="100%" alt="Banner" />
                                        </SwiperSlide>

                                    ))
                                        :
                                        <img src={selectDraw.banner} width="100%" alt="Banner" />
                                }
                            </Swiper>
                        }
                        <Outlet />
                    </Grid>
                </Box>
            </Grid>
            <SwipeableTemporaryDrawer open={menu} handleClose={() => { setMenu(false) }} />
        </Container>
    )
}
export default AppLayout;
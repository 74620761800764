import { Autocomplete, Button, CircularProgress, Collapse, Divider, FormControl, FormHelperText, FormLabel, Grid, Menu, MenuItem, OutlinedInput, Select, TextField, Typography, useTheme } from "@mui/material"
import moment, { Moment, isMoment } from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { currencyToNumber, moneyMask } from "utils/mask";
import { DatePicker } from "@mui/x-date-pickers";
import { BRAZIL_STATES } from "utils";
import { UtilsService } from "service/utils";
import { MdArrowDropDown, MdArrowDropUp, MdClose, MdFullscreen } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { BetService } from "service/bets";
import { toast } from "react-toastify";
import { IListQuery, IReport } from "./interface";
import TableEstatistic from "./table";
import { FaFilter } from "react-icons/fa6";

const StatisticPage = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
        setValue,
        watch,
        control,
        resetField
    } = useForm<IListQuery>()

    const [loading, setLoading] = useState(false);

    const [loadingDownload, setLoadingDownload] = useState(false);


    const [minBetValue, setMinBetValue] = useState<number>(0)

    const [maxBetValue, setMaxBetValue] = useState<number>(0)

    const [report, setReport] = useState<IReport[]>([])

    const [total, setTotal] = useState<number>(0)

    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(10)

    const [openReport, setOpenReport] = useState(false)

    const [useFilter, setUseFilter] = useState(false)

    const [districtsDataBase, setDistrictDataBase] = useState<{
        city: string,
        uf: {
            sigla: string,
            name: string,
        }
    }[]>([])

    const [stateToFilter, setStateToFilter] = useState<string | null>(null)

    useEffect(() => {
        setValue("minBetValue", currencyToNumber(minBetValue.toString()))
    }, [minBetValue])

    useEffect(() => {
        setValue("maxBetValue", currencyToNumber(maxBetValue.toString()))
    }, [maxBetValue])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "sellerUf" && value.sellerUf) {
                setStateToFilter(value.sellerUf)
                resetField("sellerCity")
            }
        }
        )
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        // setValue('beginDateTime', moment().set("date", 1))
        // setValue('beginPrizeDrawDate', moment().set("date", 1))
        UtilsService.getDistricts().then((data) => setDistrictDataBase(data))
        getReport({
            page,
            limit
        })
    }, [])

    const submit = (data: IListQuery) => {
        validate(data)
        getReport(data)
    }

    const getReport = (query?: any, download: boolean = false) => {

        if (download) {
            setLoadingDownload(true)
        } else {
            setLoading(true)
        }
        BetService.getReport(query ? {
            ...query,
            page: query?.page ?? page,
            limit: query?.limit ?? limit
        } : undefined)
            .then(({
                data
            }) => {
                if (download) {
                    window.open(data, '_blank');
                } else {
                    setTotal(data.count)
                    setReport(data.result)
                }
            })
            .catch((err) => {
                toast(err?.response?.message ?? "Falha ao buscar estatísticas", {
                    type: "error"
                })
            })
            .finally(() => {
                if (download) {
                    setLoadingDownload(false)
                } else {
                    setLoading(false)
                }
            })
    }

    const download = (data: IListQuery) => {
        validate(data)
        getReport({
            ...(useFilter && {
                ...data
            }),
            export: true
        }, true)
    }

    const resetFilters = () => {
        reset({
            beginDateTime: null,
            beginPrizeDrawDate: null,
            endDateTime: null,
            endPrizeDrawDate: null,
            sellerName: '',
            clientName: '',
            sellerUf: 'none',
            sellerCity: 'none'
        })
        setMinBetValue(0)
        setMaxBetValue(0)
        setStateToFilter(null)
        getReport(undefined)
        setUseFilter(false)
    }

    const validate = (data: IListQuery): IListQuery => {
        const keyHours = ["beginDateTime", "endDateTime", "beginPrizeDrawDate", "endPrizeDrawDate"]
        for (let d of Object.keys(data)) {
            const _d = d as unknown as keyof IListQuery
            const type = typeof data[_d]
            if (type === "number") {
                if ((data[_d] as number) <= 0) delete data[_d]
            }
            if (type === "string") {
                if (data[_d] === "none" || data[_d] === "") delete data[_d]
                if (_d === "sellerName" || _d === "clientName") data[_d] = data[_d]?.toUpperCase()
            }
            if (keyHours.includes(_d)) {
                if (isMoment(data[_d])) {
                    //@ts-ignore
                    data[_d] = moment(data[_d]).format("YYYY/MM/DD")
                } else {
                    delete data[_d]
                }

            }
        }
        return data
    }

    return (
        <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'} alignItems="center">
            <Typography variant="h2" textAlign={"center"} fontWeight={"bold"}>
                Estatística
            </Typography>
            <Grid container justifyContent={"flex-end"}>
                <Button
                    startIcon={<FaFilter size={15} />}
                    endIcon={useFilter ? <MdArrowDropUp /> : <MdArrowDropDown />}
                    onClick={() => {
                        if (useFilter) {
                            resetFilters()
                        }
                        setUseFilter(!useFilter)

                    }}

                >Filtrar</Button>
            </Grid>
            <Collapse in={useFilter}>
                <Grid container gap={2} component={"form"} onSubmit={handleSubmit(submit)}>
                    <Typography
                        width={"100%"}
                        variant="h5"
                        textAlign={"center"}
                        fontWeight={"bold"}
                        textTransform={'uppercase'}>
                        Filtrar por:
                    </Typography>
                    <Typography variant="h5" mt={2}>Usuário</Typography>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{
                                mb: 1
                            }}>Correspondente</FormLabel>
                            <OutlinedInput
                                placeholder="Ex.: MARIA"
                                {...register("sellerName")}
                                error={!!errors.sellerName}
                            />
                            <FormHelperText>{errors.sellerName?.message}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="sellerUf"
                            defaultValue={"none"}
                            render={(({ field }) => (
                                <FormControl fullWidth>
                                    <FormLabel sx={{
                                        mb: 1
                                    }}>Estado do correspondente</FormLabel>
                                    <Select
                                        {...field}
                                        defaultValue={"none"}
                                    >
                                        <MenuItem value={"none"} disabled>Selecione...</MenuItem>
                                        {BRAZIL_STATES.map((state) => <MenuItem value={state}>{state}</MenuItem>)}
                                    </Select>

                                    <FormHelperText>{errors.sellerUf?.message}</FormHelperText>
                                </FormControl>
                            ))}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="sellerCity"
                            defaultValue={"none"}
                            render={(({ field }) => (
                                <FormControl fullWidth>
                                    <FormLabel sx={{
                                        mb: 1
                                    }}>Cidade do correspondente</FormLabel>
                                    <Select

                                        {...field}
                                        defaultValue={"none"}
                                    >
                                        <MenuItem value={"none"} disabled>Selecione...</MenuItem>
                                        {districtsDataBase.filter((district) => district.uf.sigla === stateToFilter).sort((a, b) => {
                                            if (a.city < b.city) {
                                                return -1;
                                            }
                                            if (a.city > b.city) {
                                                return 1;
                                            }
                                            return 0;
                                        }).map(({ city }) => <MenuItem value={city}>{city}</MenuItem>)}
                                    </Select>

                                    <FormHelperText>{errors.sellerCity?.message}</FormHelperText>
                                </FormControl>
                            ))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{
                                mb: 1
                            }}>Cliente</FormLabel>
                            <OutlinedInput
                                placeholder="Ex.: JOAO"
                                {...register("clientName")}
                                error={!!errors.clientName}
                            />
                            <FormHelperText>{errors.clientName?.message}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Divider />
                    <Typography variant="h5" mt={2}>Bilhete</Typography>
                    <Grid container xs={12} direction={"row"} gap={1} justifyContent={"space-between"}>
                        <Grid xs={5.8}>
                            <FormControl>
                                <FormLabel>Valor mínimo</FormLabel>
                                <TextField
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    placeholder="R$ 00,00"
                                    value={moneyMask(minBetValue.toString())}
                                    onChange={(e) => {
                                        setMinBetValue(e.target.value as any)
                                    }}
                                    error={!!errors.minBetValue}
                                />
                                <FormHelperText>{errors.minBetValue?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid xs={5.8}>
                            <FormControl>
                                <FormLabel>Valor máximo</FormLabel>
                                <TextField
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    placeholder="R$ 00,00"
                                    value={moneyMask(maxBetValue.toString())}
                                    onChange={(e) => {
                                        setMaxBetValue(e.target.value as any)
                                    }}
                                    error={!!errors.maxBetValue}
                                />
                                <FormHelperText>{errors.maxBetValue?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} direction={"row"} gap={1} justifyContent={"space-between"}>
                        <Grid xs={5.8}>
                            <Controller
                                name="beginDateTime"
                                control={control}
                                defaultValue={null}
                                render={({
                                    field: { value, ref, onBlur, onChange, disabled },
                                }) => (
                                    <FormControl variant="outlined" fullWidth error={false}>
                                        <FormLabel>Do dia</FormLabel>
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    error: false,
                                                },
                                            }}
                                            value={moment(value)}
                                            disableFuture
                                            inputRef={ref}
                                            format="DD/MM/YYYY"
                                            disabled={disabled}
                                            onChange={onChange}
                                            onAccept={onChange}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid xs={5.8}>
                            <Controller
                                name="endDateTime"
                                control={control}
                                defaultValue={null}
                                render={({
                                    field: { value, ref, onBlur, onChange, disabled },
                                }) => (
                                    <FormControl variant="outlined" fullWidth error={false}>
                                        <FormLabel>Até o dia</FormLabel>
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    error: false,
                                                },
                                            }}
                                            value={moment(value)}
                                            disableFuture
                                            inputRef={ref}
                                            format="DD/MM/YYYY"
                                            disabled={disabled}
                                            onChange={onChange}
                                            onAccept={onChange}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Divider />
                    <Typography variant="h5" mt={2}>Concurso</Typography>

                    <Grid container xs={12} direction={"row"} gap={1} justifyContent={"space-between"}>
                        <Grid xs={5.8}>
                            <Controller
                                name="beginPrizeDrawDate"
                                control={control}
                                defaultValue={null}
                                render={({
                                    field: { value, ref, onBlur, onChange, disabled },
                                }) => (
                                    <FormControl variant="outlined" fullWidth error={false}>
                                        <FormLabel>Sorteio do dia</FormLabel>
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    error: false,
                                                },
                                            }}
                                            value={moment(value)}
                                            disableFuture
                                            inputRef={ref}
                                            format="DD/MM/YYYY"
                                            disabled={disabled}
                                            onChange={onChange}
                                            onAccept={onChange}
                                        />
                                        {/* <FormHelperText>{errors.beginPrizeDrawDate?.message}</FormHelperText> */}
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid xs={5.8}>
                            <Controller
                                name="endPrizeDrawDate"
                                control={control}
                                defaultValue={null}
                                render={({
                                    field: { value, ref, onBlur, onChange, disabled },
                                }) => (
                                    <FormControl variant="outlined" fullWidth error={false}>
                                        <FormLabel>Sorteio até o dia</FormLabel>
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    error: false,
                                                },
                                            }}
                                            value={moment(value)}
                                            inputRef={ref}
                                            format="DD/MM/YYYY"
                                            disabled={disabled}
                                            onChange={onChange}
                                            onAccept={onChange}
                                        />
                                        {/* <FormHelperText>{errors.endPrizeDrawDate?.message}</FormHelperText> */}
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction={"column"} gap={2}>
                        <Button variant="text" startIcon={<MdClose />} onClick={resetFilters}>Limpar filtros</Button>
                        <Button variant="contained" type="submit">Aplicar filtros</Button>
                    </Grid>
                </Grid>
            </Collapse>
            <Grid container>
                <Button
                    fullWidth
                    color="inherit"
                    variant="outlined"
                    sx={{ p: 1 }}
                    onClick={() => setOpenReport(true)}
                    endIcon={loading && <CircularProgress size={24} />}
                    disabled={loading}
                >
                    <MdFullscreen color="black" size={25} />
                    <Typography fontSize={16} fontWeight="500">Abrir relatório</Typography>
                </Button>
                <Button
                    fullWidth
                    color="inherit"
                    variant="outlined"
                    sx={{ p: 1, mt: 2 }}
                    type="button"
                    onClick={() => {
                        download(getValues())
                    }}
                    endIcon={loadingDownload && <CircularProgress size={24} />}
                    disabled={loadingDownload}
                >
                    <SiMicrosoftexcel color="green" size={20} />
                    <Typography fontSize={16} fontWeight="500" ml={1}>Exportar como EXCEL</Typography>
                </Button>
            </Grid>
            <TableEstatistic
                handleClose={() => setOpenReport(false)}
                open={openReport}
                report={report}
                key={'report'}
                total={total}
                onChangePagination={({
                    limit,
                    page
                }) => {
                    setPage(page)
                    setLimit(limit)
                    submit({
                        ...(useFilter && {
                            ...getValues()
                        }),
                        page: page,
                        limit: limit
                    })
                }}

            />
        </Grid>
    )
}
export default StatisticPage;
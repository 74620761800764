import { InputsFormsRegister } from "types";
import api from "../api"
import { keyPixTypeProp } from "components/withdraw";
import StorageService from "service/storage";
import { Utils } from "utils";

export class CommissionService {
    static getCommissionPolicies() {
        return api.get('/credits/commissions-policies')
    }

    static createDeposit(value: number) {
        return api.post('/credits/deposit', {
            value
        })
    }

    static getDeposit(id: number) {
        return api.get('/credits/deposit/' + id)
    }
}
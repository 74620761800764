import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IndicationsProps from 'types/indications';
import RotateComponente from 'components/rotate';
import TableView from 'components/table';
import { ComissionReportProps } from 'types';
import moment from 'moment';
import { NameMask } from 'utils/mask';

interface TableCommissionProps {
    open: boolean,
    handleClose: () => void;
    comission: ComissionReportProps[] | null
}

export default function TableCommission({ open, handleClose, comission }: TableCommissionProps) {

    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0 } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
                // overflow: 'hidden'
            }}>
                <RotateComponente>
                    <TableView
                        align='center'
                        header={['Concurso', 'Correspondente', 'Tabela', 'Vendas', 'Comissão (%)', 'Comissão(R$)']}
                    >
                        <TableBody>
                            {
                                comission?.map(({
                                    prizeDrawAt,
                                    prizeDrawId,
                                    seller,
                                    level,
                                    totalSalled,
                                    normalCommission,
                                    percentage
                                }, index) => {
                                    const color = index % 2 === 0 ? '#316FE840' : '#316FE824'
                                    const NameSeller = NameMask(seller?.name ?? "")
                                    return (
                                        <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: color }}
                                        >
                                            <TableCell align="center">
                                                {prizeDrawId}, {String(moment(prizeDrawAt).format("dddd")).toUpperCase()} <br />
                                                {String(moment(prizeDrawAt).format("L")).toUpperCase()} às {String(moment(prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                                            </TableCell>
                                            <TableCell align="center">
                                                {NameSeller.firstName} {NameSeller.lastName}
                                            </TableCell>
                                            <TableCell align="center">
                                                {level === 0 && "Tabela padrão"}
                                                {/* {level === 1 && "Tabela bonificada"} */}
                                                {level === 1 && "Indicação direta"}
                                                {/* {level === 3 && "Indicação cliente"} */}
                                                {level === 2 && "Indicação indireta 1° Grau"}
                                                {level === 3 && "Indicação indireta 2° Grau"}
                                                {level === 4 && "Indicação indireta 3° Grau"}
                                                {level === 5 && "Indicação indireta 4° Grau"}
                                            </TableCell>
                                            <TableCell align="center">
                                                {(totalSalled ?? 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                            <TableCell align="center">
                                                {(100 * percentage)}%
                                            </TableCell>
                                            <TableCell align="center">
                                                {((normalCommission) ?? 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </TableView>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>

                </Button> */}
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import { Button, Divider, FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material"
import useAuth from "context/auth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { MdFullscreen } from "react-icons/md"
import { IndicationsService } from "service/indications";
import TableModal from "./table";
import IndicationsProps, { IGerencialReport } from "types/indications";
import { FiCopy } from "react-icons/fi";
import { QRCodeComponent } from "components/qrCode";
import { PrizeDrawsService } from "service/prizes";
import moment from "moment";
import { prizeDrawsProps } from "types";
import TableGerencialReport from "./tableGerencialReport";
import { Roles } from "utils";
import { IPolicies } from "../comissionsTable";
import { CommissionService } from "service/commission";

// @ts-ignore
function separarIndications(objeto: any, camadaAtual: any) {
    if (!objeto || !Array.isArray(objeto)) {
        return [];
    }

    const camada: any[] = [];
    for (const item of objeto) {
        if (item) {
            const { ...resto } = item;
            camada.push({ ...resto });
            if (item?.indications) {
                if (item?.indications && camadaAtual > 1) {
                    camada.push(...separarIndications(item?.indications, camadaAtual - 1));
                } else if (!item?.indications && camadaAtual === 1) {
                    camada.push({ ...resto });
                }
            }
        }
    }
    return camada;
}


function getAllIndications(obj: any) {
    const indicationsSet = new Set();

    function traverse(item: any) {
        if (item.indications) {
            item.indications.forEach((indication: any) => {
                indicationsSet.add(indication);
                traverse(indication);
            });
        }
    }

    obj.forEach((item: any) => {
        indicationsSet.add(item)
        if (item.indications) {
            traverse(item)
        }
    });
    return Array.from(indicationsSet);
}


const CorrespondentIndication = () => {

    const theme = useTheme();

    const { user } = useAuth();
    const [openViewTable, setOpenViewTable] = useState(false);
    const [indicationsAll, setIndicationsAll] = useState<IndicationsProps[] | null>(null);
    const [indications, setIndications] = useState<IndicationsProps[] | null>(null);
    const [filter, setFilter] = useState('10');

    const [policies, setPolicies] = useState<IPolicies[]>([])

    useEffect(() => {
        CommissionService.getCommissionPolicies()
            .then(({ data }) => {
                setPolicies(data.policies)
            })
    }, [])

    const indicationLink = `https://numerofavorito.com/sejacorrespondente?${user?.role === Roles.seller ? "s" : "g"}=${user?.uuid}`

    const GetIndications = (__indications: IndicationsProps[] | null) => {
        const camadaDireta = separarIndications(__indications, 1) ?? [];
        const camada1 = separarIndications(camadaDireta.map((item: any) => item?.indications).flat(), 1) ?? [];
        const camada2 = separarIndications(camada1.map((item: any) => item?.indications).flat(), 1) ?? [];
        const camada3 = separarIndications(camada2.map((item: any) => item?.indications).flat(), 1) ?? [];
        const camada4 = separarIndications(camada3.map((item: any) => item?.indications).flat(), 1) ?? [];

        return { camada1, camada2, camada3, camada4, camadaDireta }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setFilter(event.target.value as string);

        const { camada1, camada2, camada3, camada4, camadaDireta } = GetIndications(indicationsAll)
        if (event.target.value === '5') {
            setIndications(camadaDireta)
            return
        }
        if (event.target.value === '20') {
            setIndications(camada1)
            return
        }
        if (event.target.value === '30') {
            setIndications(camada2)
            return
        }
        if (event.target.value === '40') {
            setIndications(camada3)
            return
        }
        if (event.target.value === '50') {
            setIndications(camada4)
            return
        }
        else {
            const allIndications: any = getAllIndications(indicationsAll);
            setIndications(allIndications)
        }
    };

    const copyLink = () => {
        navigator.clipboard.writeText(indicationLink);
        toast('Link copiado!', {
            type: 'success'
        })
    }

    const renderPoliciesOptions = (policy: IPolicies): {
        value: string,
        label: string
    } => {
        if (policy.level === 0) {
            return {
                label: policy.description ?? "Diretas",
                value: "5"
            }
        } else if (policy.level === 1) {
            return {
                label: policy.description ?? "Indiretas 1º Grau",
                value: "20"
            }
        } else if (policy.level === 2) {
            return {
                label: policy.description ?? "Indiretas 2º Grau",
                value: "30"
            }
        } else if (policy.level === 3) {
            return {
                label: policy.description ?? "Indiretas 3º Grau",
                value: "40"
            }
        } else if (policy.level === 4) {
            return {
                label: policy.description ?? "Indiretas 4º Grau",
                value: "50"
            }
        } else if (policy.level === 5) {
            return {
                label: policy.description ?? "Indiretas 5º Grau",
                value: "60"
            }
        } else {
            return {
                label: "Opção desconhecida..",
                value: ""
            }
        }
    }

    useEffect(() => {
        IndicationsService.get()
            .then(({ data }) => {
                if (data?.sellers) {
                    setIndicationsAll(data.sellers)
                    const allIndications: any = getAllIndications(data?.sellers);
                    setIndications(allIndications)
                }
            })
            .catch(() => { })
    }, [])

    return (
        <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'}>
            <Typography variant="h2" textAlign={"center"} fontWeight={"bold"} textTransform={'uppercase'}>
                Indicação de novos correpondentes
            </Typography>
            <Grid
                onClick={copyLink}
                component={Button}
                p={2}
                fullWidth
                variant="contained" flexDirection={'column'}>
                <Typography variant="h5" color="white">
                    Copiar meu <strong> link de indicação</strong> para convidar novos correspondentes
                </Typography>
            </Grid>
            <Grid container p={2} bgcolor="#0000000A" alignItems="center" justifyContent="space-between">
                <Typography width="calc(100% - 45px)" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {indicationLink}
                </Typography>
                <IconButton onClick={copyLink}>
                    <FiCopy />
                </IconButton>
            </Grid>
            <QRCodeComponent key={indicationLink} link={indicationLink} imageName="Link de indicação" title="Baixar QRCODE de indicação" />

            <Divider orientation="horizontal" />
            <Typography
                variant="h4"
                textAlign={"center"}
                fontWeight={"bold"}
                textTransform={'uppercase'}>
                {user?.role === Roles.seller && <>
                    MINHAS INDICAÇÕES
                </>}

                {user?.role === Roles.general && <>
                    MEUS CORRESPONDENTES
                </>}
            </Typography>
            {
                user?.role === Roles.seller && <>
                    <Typography
                        variant="h6"
                        textAlign={"center"}
                        fontWeight={"bold"}
                        textTransform={'uppercase'}>
                        Filtrar por:
                    </Typography>
                    <Grid container flexDirection='column'>
                        <Typography
                            variant="h6"
                            textAlign={"start"}
                            fontWeight={"bold"}
                            color={theme.palette.grey[700]}>
                            Tipo de indicações
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                value={filter}
                                // variant="filled"
                                onChange={handleChange}
                            >
                                <MenuItem value={'10'}>Todas</MenuItem>
                                <Divider orientation="horizontal" />
                                {policies.filter((item) => item.active && item.role === 10).map((item) => {
                                    const { label, value } = renderPoliciesOptions(item)
                                    return (
                                        <MenuItem value={value}>{label}</MenuItem>
                                    )
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                </>
            }
            <Button color="inherit" variant="outlined" sx={{ p: 1 }} onClick={() => setOpenViewTable(true)}>
                <MdFullscreen color="black" size={25} />
                <Typography fontSize={16} fontWeight="500" mt={.4}>Abrir relatório</Typography>
            </Button>

            <TableModal
                indicators={indications}
                open={openViewTable}
                handleClose={() => {
                    setOpenViewTable(false);
                }}
            />

        </Grid>
    )
}
export default CorrespondentIndication;
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import useBet from 'context/bet';
import { useEffect, useRef, useState } from 'react';

const BannerCarrousel = () => {


    const { prizesDraws } = useBet();

    const [PlayCarrouselBanner, setPlayCarrouselBanner] = useState(true);
    const swiperRefLocal = useRef<SwiperRef | null>(null);

    const onPlayCarrousel = () => {
        setPlayCarrouselBanner(!PlayCarrouselBanner)

    };

    useEffect(() => {
        if (PlayCarrouselBanner) {
            swiperRefLocal?.current?.swiper?.autoplay?.start()
        } else {
            swiperRefLocal?.current?.swiper?.autoplay?.stop()
        }
    }, [PlayCarrouselBanner, swiperRefLocal])

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            modules={[Autoplay]}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
        >
            <Swiper
                spaceBetween={0}
                ref={swiperRefLocal}
                slidesPerView={1}
                modules={[Autoplay]}
                autoplay={{
                    delay: 2500,
                    pauseOnMouseEnter: true
                }}
            >
                {
                    prizesDraws?.map((prizes) => (
                        <SwiperSlide
                            onClick={onPlayCarrousel}

                            key={prizes.id}>
                            <img src={prizes.banner} width="100%" alt="Banner" />
                        </SwiperSlide>

                    ))
                }
            </Swiper>
        </Swiper>
    )
}

export default BannerCarrousel;
import { Card, CardContent, Container, Grid, Typography, colors, useMediaQuery, useTheme } from "@mui/material"

import CardDraw from "components/cards/draw";
import { useEffect, useState } from "react";
import FavoriteNumber from "./favoriteNumber";
import { sendToShow } from "utils";
import { PrizeDrawsService } from "service/prizes";
import moment from "moment";
import { prizeDrawsProps } from "types";
import useBet, { PredictionPros, PredictionsProps } from "context/bet";
import StampBlue from "assets/imgs/SELO AZUL.png"
import StampOrange from "assets/imgs/SELO LARANJA.png"
import StampGreen from "assets/imgs/SELO VERDE.png"
import { IQRCODECards } from "types/bet";
import { useLocation, useNavigate } from "react-router-dom";
import RoutesPath from "utils/routesBases";
import { NumbersOptions } from "./numbersOptions";
import { RequestSeller } from "./order/requestSeller";

export const getColorDay = (day: string): {
    color: string,
    img: string
} => {
    const diaDaSemana = moment(day).day();
    switch (diaDaSemana) {
        case 0: // Domingo
        case 2: // Terça
        case 4: // Quinta
            return {
                color: '#00A410',
                img: StampGreen
            };
        case 1: // Segunda
        case 3: // Quarta
        case 5: // Sexta
            return {
                color: '#316FE8',
                img: StampBlue
            };
        case 6: // Sábado
            return {
                color: '#EC6615',
                img: StampOrange
            };
        default:
            return {
                color: '#00A410',
                img: StampGreen
            };
    }
}

const PrizeDrawPage = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const { selectDraw, setSelectedDraw, setPrizesDraws, prizesDraws, purchaseFlow, hoursToShowStamp, setOrder, order, setPrediction, calcDiscount } = useBet();

    const [card, setCard] = useState<IQRCODECards | null>(null)

    const [openModalSeller, setOpenModalSeller] = useState(false)

    useEffect(() => {
        sendToShow("favorite_number")
    }, [selectDraw])

    useEffect(() => {
        PrizeDrawsService.get()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setPrizesDraws(data.prizeDraws);
                }
            })

        if (location.state?.card) {
            setCard(location.state?.card)
        }
        if (location.pathname.includes("apostar")) {
            setOpenModalSeller(true)
        }
    }, [])

    const handleCardNumbers = (numbers: string[]) => {
        let _predict: PredictionsProps = {}
        for (let i = 0; i < numbers.length; i++) {
            let key = `predication${i + 1}`
            let split = numbers[i].split("")
            let newPredict: PredictionPros[] = []
            for (let x = 0; x < split.length; x++) {
                newPredict.push({
                    id: x,
                    number: Number(split[x])
                })
            }
            _predict = {
                ..._predict,
                [key]: newPredict
            }
        }
        setPrediction(_predict);
    }

    return (
        <Container>
            <Grid container py={2} gap={2}>
                <Typography variant="h3" textAlign='center' fontWeight="400" width={"100%"} id={"prize-draws-select"}>
                    <strong>Selecione abaixo</strong> o dia e o horário do concurso que deseja participar
                </Typography>
                <Grid p={2} container gap={4}>
                    {
                        prizesDraws?.map((data, index) => (
                            <Grid container position={"relative"}>
                                <CardDraw
                                    index={index + 1}
                                    key={data?.id}
                                    id={data?.id ?? null}
                                    selected={selectDraw?.id ?? null}
                                    onChange={(id) => {
                                        setSelectedDraw(data)
                                        if (card) {
                                            handleCardNumbers(card.numbers)
                                            setOrder({
                                                group: 10,
                                                value: calcDiscount(10, data.numberPrice * 10).value
                                            })
                                            navigate("/correspondente/venda/order", {
                                                state: {
                                                    card
                                                }
                                            })
                                        }
                                    }}
                                    color={getColorDay(data.prizeDrawAt)}
                                    day={String(moment(data.prizeDrawAt).format("dddd")).toUpperCase()}
                                    date={String(moment(data.prizeDrawAt).format("L")).toUpperCase()}
                                    hors={String(moment(data.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()}
                                    prizeDrawFrom={data.prizeDrawFrom}
                                    prizes={data.prizes}
                                    numberPrice={data.numberPrice}
                                    dayNumber={moment(data.prizeDrawAt).day()}
                                />
                                {String(moment(data.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase() === hoursToShowStamp &&
                                    <img
                                        onClick={() => setSelectedDraw(data)}
                                        className="stampImg"
                                        src={getColorDay(data.prizeDrawAt).img}
                                        style={{
                                            position: 'absolute',
                                            right: -30,
                                            bottom: -10,
                                            cursor: "pointer",
                                            width: purchaseFlow === "saller" ? "80px" : "115px"
                                        }}
                                    />}
                            </Grid>

                        ))
                    }
                </Grid>
                {
                    selectDraw && !order &&
                    <NumbersOptions />
                }
                {
                    selectDraw && order &&
                    <FavoriteNumber />
                }
                <RequestSeller
                    open={openModalSeller}
                    handleClose={(success) => {
                        setOpenModalSeller(false)
                        if (success) {
                            sendToShow("prize-draws-select", "start")
                        } else {
                            navigate("/cliente/concurso")
                        }
                    }}
                />
            </Grid>
        </Container>
    )
}

export default PrizeDrawPage;
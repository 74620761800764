import style from './styles.module.css'
import { Button, Container, Grid, IconButton, SwipeableDrawer, Typography, useTheme, Menu, MenuItem } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import { MdArrowForwardIos } from 'react-icons/md'
import LogoLp from "assets/imgs/lp/whoWeAre/LOGO LP.png"
import { useState } from 'react';
import { IoMenu } from 'react-icons/io5';
import { Link, Navigate } from 'react-router-dom';
import { LP } from 'pages/lp';
import { LPContest } from 'pages/lp/contest';
import { LPCorrespondent } from 'pages/lp/correspondent';
import { LPRegulation } from 'pages/lp/regulation';
import { LPContact } from 'pages/lp/contact';
import useQuery from 'hooks/useQuery';

export function LPNav() {
    const theme = useTheme()
    const matches = useMediaQuery('(min-width:1330px)');
    const [open, setOpen] = useState(false)
    const query = useQuery()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuButton = Boolean(anchorEl);

    const handleOpenMenuButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenuButton = () => {
        setAnchorEl(null);
    };

    const redirect = (path: string[]): string => {
        let _query = "?"
        let correspondent = query.get('s')
        let client = query.get('c')
        if (client) {
            _query += `c=${client}&`
        }
        if (correspondent) {
            _query += `s=${correspondent}&`
        }
        let url = (path[0] + _query)
        return url
    }

    return (
        <nav className={style.nav}>
            <img src={LogoLp} width={80} />
            {!matches && !open &&
                <div>
                    <IconButton onClick={() => setOpen(true)}>
                        <IoMenu color={theme.palette.primary.main} style={{
                            width: 70,
                            height: 40,
                            marginTop: 20
                        }} />
                    </IconButton>
                    <Typography variant='h6' textAlign="center" fontWeight="bold" mt={-2} color={theme.palette.primary.main}>
                        Menu
                    </Typography>
                </div>}
            {matches ? <ul className={style.ul}>
                {lpMenus.filter((item) => !item.path[0].includes("/sejacorrespondente")).map((menu) => {
                    if (menu.type === "route") {
                        if (!menu.path.includes("*")) {
                            return (
                                <Link to={redirect(menu.path)} className={style.link}>
                                    <li style={{
                                        ...(menu.path.includes(window.location.pathname) && {
                                            color: theme.palette.primary.main
                                        })
                                    }}>{menu.label}</li>
                                </Link>
                            )
                        }
                    } else if (menu.type === "external_link") {
                        return (
                            <Link to={menu.path[0]} target='_blank' className={style.link}>
                                <li style={{
                                    ...(menu.path.includes(window.location.pathname) && {
                                        color: theme.palette.primary.main
                                    })
                                }}>{menu.label}</li>
                            </Link>
                        )
                    } else {

                        return (
                            <div className={style.buttonDiv}>
                                <div />
                                <Button
                                    id="basic-button"
                                    aria-controls={menuButton ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={menuButton ? 'true' : undefined}
                                    onClick={handleOpenMenuButton}
                                >
                                    {menu.label}
                                </Button>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={menuButton}
                                    onClose={handleCloseMenuButton}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    disableScrollLock={true}
                                >
                                    <Link to={redirect(menu.path)} className={style.link}>
                                        <MenuItem className={style.menuItemButton} onClick={handleCloseMenuButton}>Sou cliente</MenuItem>
                                    </Link>
                                    <Link to={redirect(['/correspondente'])} className={style.link}>
                                        <MenuItem className={style.menuItemButton} onClick={handleCloseMenuButton}>Sou revendedor</MenuItem>
                                    </Link>
                                </Menu>

                            </div>
                        )
                    }
                })}
            </ul>
                :
                <SwipeableDrawer
                    anchor={'right'}
                    open={open}
                    onClose={() => {
                        setOpen(false)
                        if (menuButton) handleCloseMenuButton()
                    }}
                    onOpen={() => { }}
                    PaperProps={{
                        sx: { height: '100vh', width: '100%', maxWidth: 300 }
                    }}
                >
                    <Container maxWidth="xs" sx={{ p: 0 }}>
                        <Grid container bgcolor="white" height='100%' maxWidth={300} alignItems="center" justifyContent={"center"}>
                            <Grid p={4}>
                                <img src={LogoLp} width="100%" />
                            </Grid>
                            <Grid container mt={4}>
                                {
                                    lpMenus.filter((item) => !item.path[0].includes("/sejacorrespondente")).map(({ label, path, type }, index) => {
                                        const colorBG = path.includes(window.location.pathname) ? theme.palette.grey[300] : 'transparent';
                                        const color = path.includes(window.location.pathname) ? theme.palette.primary.main : "#666666";
                                        if (type === "button" && index === lpMenus.filter((item) => !item.path[0].includes("/sejacorrespondente")).findIndex((m) => m.type === "button")) {
                                            return (
                                                <>
                                                    <div className={style.divider} />
                                                    <Grid
                                                        key={path[0]}
                                                        sx={{ backgroundColor: colorBG, ':focus': { backgroundColor: colorBG } }}
                                                        justifyContent={'flex-start'}
                                                        borderRadius={0}
                                                        p={3}
                                                        container
                                                        flexWrap={'nowrap'}
                                                        gap={2}
                                                        alignItems="center"
                                                        component={Button}
                                                    >
                                                        <Button
                                                            id="basic-button"
                                                            aria-controls={menuButton ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={menuButton ? 'true' : undefined}
                                                            onClick={handleOpenMenuButton}
                                                            className={style.menuItem}
                                                            sx={{
                                                                fontSize: 16,
                                                                color: "#666666",
                                                                padding: 0,
                                                                justifyContent: 'space-between',
                                                                width: '100%',
                                                            }}
                                                            endIcon={<MdArrowForwardIos size={14} color={color} />}
                                                        >
                                                            {label}
                                                        </Button>

                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={menuButton}
                                                            onClose={() => {
                                                                handleCloseMenuButton()
                                                            }}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: 'visible',
                                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                    mt: 1.5,
                                                                    '&::before': {
                                                                        content: '""',
                                                                        display: 'block',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 14,
                                                                        width: 10,
                                                                        height: 10,
                                                                        bgcolor: 'background.paper',
                                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                                        zIndex: 0,
                                                                    },
                                                                },
                                                            }}
                                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                            disableScrollLock={true}
                                                        >
                                                            <Link to={redirect(path)} className={style.link}>
                                                                <MenuItem className={style.menuItemButton} onClick={handleCloseMenuButton}>Sou cliente</MenuItem>
                                                            </Link>
                                                            <Link to={redirect(['/correspondente'])} className={style.link}>
                                                                <MenuItem className={style.menuItemButton} onClick={handleCloseMenuButton}>Sou revendedor</MenuItem>
                                                            </Link>
                                                        </Menu>
                                                    </Grid>
                                                </>

                                            )
                                        } else if (type === "external_link") {
                                            return (
                                                <Link to={path[0]} target='_blank' className={style.link} style={{
                                                    width: '100%'
                                                }}>
                                                    <Grid
                                                        key={path[0]}
                                                        sx={{ backgroundColor: colorBG, ':focus': { backgroundColor: colorBG } }}
                                                        justifyContent={'flex-start'}
                                                        borderRadius={0}
                                                        p={3}
                                                        container
                                                        flexWrap={'nowrap'}
                                                        gap={2}
                                                        alignItems="center"
                                                        component={Button}
                                                        onClick={() => {
                                                            setOpen(false)
                                                        }}
                                                    >
                                                        <div className={style.menuItem}>
                                                            <Typography textAlign="start" color={color} fontSize={16} fontWeight={500} maxWidth={200}>
                                                                {label}
                                                            </Typography>
                                                            <MdArrowForwardIos color={color} />
                                                        </div>

                                                    </Grid >
                                                </Link>
                                            )
                                        } else {
                                            if (!path.includes("*")) {
                                                return (
                                                    <>
                                                        <Link to={redirect(path)} className={style.link} style={{
                                                            width: '100%'
                                                        }}>
                                                            <Grid
                                                                key={path[0]}
                                                                sx={{ backgroundColor: colorBG, ':focus': { backgroundColor: colorBG } }}
                                                                justifyContent={'flex-start'}
                                                                borderRadius={0}
                                                                p={3}
                                                                container
                                                                flexWrap={'nowrap'}
                                                                gap={2}
                                                                alignItems="center"
                                                                component={Button}
                                                                onClick={() => {
                                                                    setOpen(false)
                                                                }}
                                                            >
                                                                <div className={style.menuItem}>
                                                                    <Typography textAlign="start" color={color} fontSize={16} fontWeight={500} maxWidth={200}>
                                                                        {label}
                                                                    </Typography>
                                                                    <MdArrowForwardIos color={color} />
                                                                </div>

                                                            </Grid >
                                                        </Link>
                                                    </>
                                                )
                                            }
                                        }
                                    })
                                }

                            </Grid>
                        </Grid>
                    </Container>
                </SwipeableDrawer>
            }
        </nav >
    )
}

export const lpMenus: {
    label: string,
    path: string[],
    type: "route" | "button" | "external_link",
    element?: JSX.Element
}[] = [
        {
            label: "Quem Somos",
            path: ["/quemsomos"],
            type: "route",
            element: <LP />
        },
        {
            path: ['*'],
            element: <Navigate to="/" />,
            type: "route",
            label: ""
        },
        {
            label: "Concursos",
            path: ["/concursos", "/", "/convite", "/convitedasorte"],
            type: "route",
            element: <LPContest />
        },
        // {
        //     label: "Perguntas frequentes",
        //     path: ["/perguntas-frequentes"],
        //     type: "route",
        //     element: <LPQuestions />
        // },
        {
            label: "Regulamento",
            path: ["/regulamento"],
            type: "route",
            element: <LPRegulation />
        },
        {
            label: "Seja nosso correspondente",
            path: ["/sejacorrespondente"],
            type: "route",
            element: <LPCorrespondent />
        },
        // {
        //     label: "Fale conosco",
        //     path: ["/fale-conosco"],
        //     type: "route",
        //     element: <LPContact />
        // },
        {
            label: "Atendimento",
            path: ["https://wa.me/+5541991816829"],
            type: "external_link"
        },
        {
            label: "Login",
            path: ["/cliente/concurso"],
            type: "button"
        },
    ]
import { useForm, SubmitHandler } from "react-hook-form"
import { CircularProgress, FormControl, FormHelperText, Grid, OutlinedInput, Typography, useTheme } from "@mui/material"
import { useState } from "react";
import { Validators } from "utils/validators";
import { CEPMaskCustom, CPFMaskCustom, } from "components/mask";
import { AuthService } from "service/auth";
import useAuth from "context/auth";
import { toast } from "react-toastify";
import Pin from "components/pin";
import { PinProps } from "pages/bet/order/awaitIdentityConfirm";
import { useLocation, useNavigate } from "react-router-dom";
import RoutesPath from "utils/routesBases";

const ClientLoginPage = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ cpf: string }>()

    const { Authenticate } = useAuth();

    const [client, setClient] = useState<{
        "id": number,
        "name": string,
        "phone": string
    } | null>(null)
    const [showPin, setShowpin] = useState(false);

    const [PinValues, setPinValues] = useState<PinProps>({
        0: null,
        1: null,
        2: null
    })

    const [loading, setLoading] = useState(false);

    const [loadingLogin, setloadingLogin] = useState(false);

    const navigate = useNavigate()

    const { search } = useLocation()

    const theme = useTheme();

    const ResetPin = () => {
        setPinValues({
            0: null,
            1: null,
            2: null
        })
    }

    const clientRequestLogin = ({ cpf }: { cpf: string }) => {
        setClient(null)
        setShowpin(false)
        setLoading(true)

        AuthService.clientLogin({
            cpf: cpf.replace(/\D/g, "")
        })
            .then(({ data }) => {
                if (data) {
                    Authenticate(data, 'client')
                }
                // setClient(data)
                // setShowpin(true)
            })
            .catch((err) => {
                if (err?.response?.data?.message === "Cliente não encontrado") {
                    navigate(RoutesPath.Clients.bet.home.path + search)
                } else {
                    toast(err?.response?.data?.message ?? "Error ao realizar login", { type: "error" })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const HandleClient = (token: string) => {
        setloadingLogin(true)
        AuthService.clientLogin({
            id: client?.id ?? '',
            token: token
        })
            .then(({ data }) => {
                if (data) {
                    Authenticate(data, 'client')
                }
            })
            .catch((err) => {
                ResetPin();
                toast(err?.response?.data?.message ?? "Error ao realizar login", { type: "error" })
            })
            .finally(() => {
                ResetPin();
                setloadingLogin(false);
            })
    }

    return (
        <Grid mt={5}>
            <Typography
                fontWeight="bold"
                variant="h2"
                textAlign="center">

            </Typography>
            <Grid container mt={5} gap={2}>
                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe seu CPF
                </Typography>
                <Grid container>
                    <FormControl fullWidth>
                        <OutlinedInput
                            error={!!errors.cpf}
                            placeholder="000.000.000-00"
                            inputComponent={CPFMaskCustom}
                            disabled={loading}
                            fullWidth
                            {...register("cpf", {
                                required: true,
                                onChange: (event) => {
                                    const value = event?.target?.value?.replace(/\D/g, '')
                                    if (value?.length === 11) {
                                        handleSubmit(clientRequestLogin)()
                                    }
                                },
                                validate: (value) => {
                                    if (!Validators.validateCPF(value)) {
                                        return "Cpf invalido!"
                                    }
                                }
                            })}
                            endAdornment={loading && <CircularProgress size={24} />}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.cpf?.message as string}</FormHelperText>
                    </FormControl>
                </Grid>
                {
                    showPin &&
                    <>
                        <Typography
                            mt={2}
                            fontWeight="500"
                            variant="h5"
                            textAlign="center">
                            Enviamos um novo código de segurança de 3 dígitos para seu <strong>SMS</strong> e <strong>Whatsapp</strong>.
                            <br />
                            Digite o código abaixo para <strong>continuar</strong>.
                        </Typography>
                        <Pin
                            desible={loadingLogin}
                            PinValues={PinValues}
                            onChangePin={(value) => {
                                setPinValues(value)
                            }}
                            onCofirm={HandleClient}
                        />
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default ClientLoginPage;
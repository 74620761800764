import CorrespondentApp from "pages/correspondent";
import AuthLayout from "layout/auth";
import AppLayout from "layout/saller";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import CorrespondentIndication from "pages/correspondent/indication";
import MyPrizeDrawsPage from "pages/correspondent/myPrizeDraws";
import TicketPage from "pages/correspondent/ticket";
import MyCommissions from "pages/correspondent/myCommissions";
import CommissionsTable from "pages/correspondent/comissionsTable";
import ResultPage from "pages/correspondent/results";
import CreditsSellerPage from "pages/correspondent/credits";
import WithdrawPage from "components/withdraw";
import RoutesPath from "utils/routesBases";
import useAuth from "context/auth";
import LoginPage from "pages/auth/correspondent/login";
import RegisterPage from "pages/auth/correspondent/register";
import TutorialPage from "pages/correspondent/tutorial";
import PubliPage from "pages/correspondent/publi";
import useQuery from "hooks/useQuery";
import { useEffect } from "react";

const SellerRouters = (): RouteObject[] => {
    const { token } = useAuth();
    const { search } = useLocation()

    if (!token) {
        return [
            {
                element: <AuthLayout />,
                path: "correspondente",
                children: [
                    {
                        element: <Navigate to={"/correspondente/login" + search}  />,
                        path: "/correspondente",
                    },
                    {
                        element: <LoginPage />,
                        path: "/correspondente/login",
                    },
                    {
                        element: <RegisterPage />,
                        path: "/correspondente/cadastro",
                    },
                ]
            },
        ]
    }

    return [
        {
            element: <AppLayout />,
            path: "correspondente",
            children: [
                {
                    element: <Navigate to={"/correspondente"} />,
                    path: "/correspondente/login",
                },
                {
                    element: <Navigate to={"/correspondente"} />,
                    path: "/correspondente/*",
                },
                {
                    element: <CorrespondentApp />,
                    path: "/correspondente",
                },
                {
                    element: <CorrespondentIndication />,
                    path: "/correspondente/convite-correspondente",
                },
                // {
                //     element: <MyPrizeDrawsPage />,
                //     path: "/correspondente/meus-concursos",
                // },
                {
                    element: <MyCommissions />,
                    path: "/correspondente/minhas-comissoes",
                },
                {
                    element: <CommissionsTable />,
                    path: "/correspondente/tabela-comissoes",
                },
                {
                    element: <CreditsSellerPage />,
                    path: "/correspondente/meusaldo",
                },
                {
                    element: <WithdrawPage />,
                    path: "/correspondente/saque",
                },
                {
                    element: <ResultPage />,
                    path: "/correspondente/resultados",
                },
                {
                    element: <TutorialPage />,
                    path: "/correspondente/aprenda",
                },
                {
                    element: <PubliPage />,
                    path: "/correspondente/publicidade",
                },
                {
                    element: RoutesPath.Correspondente.bet.home.element,
                    path: RoutesPath.Correspondente.bet.home.path,
                },
                {
                    element: RoutesPath.Correspondente.bet.order.element,
                    path: RoutesPath.Correspondente.bet.order.path,
                },
                {
                    element: RoutesPath.Correspondente.bet.payment.element,
                    path: RoutesPath.Correspondente.bet.payment.path + '/:id',
                },
                {
                    element: RoutesPath.Correspondente.bet.recibo.element,
                    path: RoutesPath.Correspondente.bet.recibo.path + '/:id',
                }, 
                {
                    element: RoutesPath.Correspondente.meusClientes.element,
                    path: RoutesPath.Correspondente.meusClientes.path,
                },
                {
                    element: RoutesPath.Correspondente.statistic.element,
                    path: RoutesPath.Correspondente.statistic.path,
                },
                {
                    element: RoutesPath.Correspondente.deposito.element,
                    path: RoutesPath.Correspondente.deposito.path
                }
            ]
        },
        {
            element: <TicketPage />,
            path: "/bilhete/:id",
        }
    ]
}

export default SellerRouters;
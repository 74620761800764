import { Box, Button, Container, Grid, IconButton, Typography, useTheme } from "@mui/material"
import SVGLOGO from "assets/imgs/logo 01.png"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react";
import useAuth from "context/auth";
import 'swiper/css';
import useBet from "context/bet";

const ClientAppLayout = () => {
    const { prizesDraws, seller, selectDraw, clientThatIndicate } = useBet();
    const navigate = useNavigate();
    const { user } = useAuth();


    return (
        <Container maxWidth="xs" sx={{ p: 0 }}>
            <Grid justifyContent='center' alignItems='center' container alignContent='flex-start'>
                <Grid container justifyContent='center' alignContent='center' p={2}>
                    <div>
                        <Grid container flexDirection='column' alignItems={'center'}>
                            <img src={SVGLOGO} width={100} />
                            <Button 
                             onClick={() => {
                                navigate('/quemsomos' + `?s=${user?.lastSellerId ?? ""}${clientThatIndicate ? `&c=${clientThatIndicate}` : ''}`)
                             }}
                            sx={{ textDecoration: 'underline' }}>Quem somos</Button>
                        </Grid>
                    </div>
                </Grid>
                <Box width={'100%'}>
                    <Outlet />
                </Box>
            </Grid>
        </Container>
    )
}
export default ClientAppLayout;
import { Button, Dialog, Grid, Typography } from "@mui/material"
import Lottie from "lottie-react"
import { Link } from "react-router-dom"
import { Assets } from "utils"

export default function CreditAdded({ show, isSeller, isDeposit, bgColor }: { show: boolean, isSeller?: boolean, isDeposit?: boolean, bgColor?: string }) {
    return (
        <Dialog open={show}
            PaperProps={{ sx: { bgcolor: 'transparent !important', boxShadow: 'none', p: 0, m: 0, maxHeight: '100vh', maxWidth: '100vw' } }}
            componentsProps={{ backdrop: { sx: { bgcolor: 'rgba(0, 0, 0, 0.7)' } } }}>
            <Grid container className="CreditAddedClass" sx={{
                ...(bgColor && {
                    backgroundColor: bgColor
                })
            }}>
                <Grid width="100vw" container alignItems={"center"} flexDirection={"column"}>
                    <Lottie animationData={Assets.Animations.confirm} loop={false} style={{ width: 100 }} />
                    <Typography color="white" variant="h2" textAlign={"center"}>
                        {isDeposit ? "Depósito realizado com sucesso!" : "Adição de Saldo efetuada com sucesso."}
                        <br />
                        <br />
                        Você poderá utilizar seu saldo para realizar apostas.
                    </Typography>
                    <br />
                    <Link to={`/${!isSeller ? 'cliente' : 'correspondente'}`}>
                        <Button style={{
                            color: 'white',
                            borderColor: 'white'
                        }} variant="outlined">Fechar</Button>
                    </Link>
                </Grid>
            </Grid>
        </Dialog>
    )
}
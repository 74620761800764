import { Button, CircularProgress, Divider, FormControl, Grid, MenuItem, Switch, Typography, useTheme } from "@mui/material"
import Menu from "components/menu";

import useAuth from "context/auth";
import { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdFullscreen, MdLiveTv } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Images from "utils/imgs";
import RoutesPath from "utils/routesBases";
import TableDetailsWithdrawView from "./creditsDetails";
import { ClientService } from "service/client";
import TablePrizes from "./prizes";
import { PrizesProps, prizeDrawsProps } from "types";
import { PrizeDrawsService } from "service/prizes";
import TableResults from "./results";
import moment from "moment";
import { BetService } from "service/bets";
import theme from "theme";
import { AuthService } from "service/auth";
import { constants } from "utils";
import { ChipItem } from "layout/bet";
import { FaWhatsapp } from "react-icons/fa6";
import useQuery from "hooks/useQuery";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";
import BannerCarrousel from "components/carrousel";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import useBet from "context/bet";
import 'swiper/css';


const ClientApp = () => {

    const { user } = useAuth();

    const { prizesDraws, setPrizesDraws } = useBet();

    const { search } = useLocation()

    const { palette } = useTheme();
    const navigate = useNavigate();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const querySimulator = queryParams.get('movements');

    const query = useQuery();

    const [prizeDrawsResult, setPrizeDrawsResult] = useState<null | prizeDrawsProps>(null);
    const [prizeDraws, setPrizeDraws] = useState<null | prizeDrawsProps[]>(null);

    const [drawParticipation, setDrawParticipation] = useState<null | {
        id: number;
        prizeDrawAt: string
    }[]>(null);

    const [showTicket, setShowTicket] = useState<boolean>(false);

    const [openDetails, setOpenDetails] = useState(false);

    const [loadingGuessCredit, setLoadingGuessCredit] = useState(false)

    const [anchorElTickets, setAnchorElTickets] = useState(false);
    const [anchorElResults, setAnchorElResults] = useState(false);

    const [prizes, setPrizes] = useState<PrizesProps[] | null>(null);

    const [showPrizes, setShowPrize] = useState<boolean>(false);

    const [PlayCarrouselBanner, setPlayCarrouselBanner] = useState(true);
    const swiperRefLocal = useRef<SwiperRef | null>(null);

    const [historic, setHistoric] = useState<{
        balance: number, // SALDO
        creditValue: number, // VALOR
        event: number | string, // DESCRIÇÃO
        createdAt: Date | string, // DATA
        kind: string, // NATUREZA,
        betUUID: string | null,
        clientCpf: string | null,
        clientName: string | null,
        eventNumber: number
    }[] | null
    >(null)

    useEffect(() => {
        getAllMovements()
    }, [])


    useEffect(() => {
        if (querySimulator) {
            setOpenDetails(true)
        }
    }, [querySimulator])


    const getAllMovements = (guess?: boolean) => {
        if (guess) {
            setLoadingGuessCredit(true)
        }
        BetService.creditsHistoric(guess)
            .then(({ data }) => {
                if (data) {
                    setHistoric(data)
                }
                if (guess) {
                    setLoadingGuessCredit(false)
                    setOpenDetails(true)
                }
            })
    }

    const handleCloseTickets = () => {
        setAnchorElTickets(false)
    }

    const handleCloseResults = () => {
        setAnchorElResults(false)
    }

    const getPrizes = () => {
        PrizeDrawsService.prizes()
            .then(({ data }) => {
                if (data?.prizes) {
                    setPrizes(data.prizes)
                }
            })
    }

    const getPrizeDrawsResults = () => {
        PrizeDrawsService.resultsclients()
            .then(({ data }) => {
                if (data?.results) {
                    setPrizeDraws(data.results)
                }
            })
    }

    const onPlayCarrousel = () => {
        setPlayCarrouselBanner(!PlayCarrouselBanner)
    };

    useEffect(() => {
        getPrizeDrawsResults();
    }, [])

    useEffect(() => {
        // ClientService.get();
        getPrizes();

        GetDrawParticipation();
    }, [])

    useEffect(() => {
        PrizeDrawsService.get()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setPrizesDraws(data.prizeDraws);
                }
            })
    }, [])


    const GetDrawParticipation = () => {
        BetService.getDrawsParticipationClient()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setDrawParticipation(data.prizeDraws)
                }
            })
            .catch(() => { })
    }

    const sendToContests = (): string => {
        let path = RoutesPath.Clients.bet.home.path
        const seller = query.get('s')
        if (seller) {
            if (seller !== `DYNqidJ0DbXR`) {
                path += `?s=${seller}`
            } else if (user?.lastSellerId) {
                path += `?s=${user?.lastSellerId}`
            } else {
                path += `?s=DYNqidJ0DbXR`
            }
        } else {
            if (user?.lastSellerId) {
                path += `?s=${user?.lastSellerId}`
            } else {
                path += `?s=DYNqidJ0DbXR`
            }
        }
        return path

    }

    return (
        <Grid container gap={4} p={2} justifyContent="center">
            <Grid container alignItems="center" gap={2} flexDirection={'column'}>
                <Grid mt={-3.6} display={"flex"} gap={2} flexWrap={"wrap"} justifyContent={"center"}>
                    <ChipItem
                        // icon={<FaWhatsapp color="green" size={20} style={{ marginLeft: '10px' }} />}
                        label="Atendimento"
                        onClick={() => {
                            window.open("https://wa.me/+5541991816829", "_blank")
                        }}
                    />
                    <ChipItem
                        icon={<MdLiveTv color={palette.primary.main} size={16} style={{ marginLeft: '10px', marginBottom: '3px' }} />}
                        label="Assistir sorteios"
                        onClick={() => navigate(RoutesPath.sorteio.path, {
                            state: {
                                prev_path: window.location.pathname
                            }
                        })}
                    />
                    <ChipItem
                        pulse
                        icon={<FaWhatsapp color="green" size={20} style={{ marginLeft: '10px' }} />}
                        label="Participe do grupo Número Favorito"
                        onClick={() => {
                            window.open("https://chat.whatsapp.com/JgXQM7gnO6tJBcXFG64gbo", "_blank")
                        }}
                    />
                </Grid>
                <Grid width={"100%"}>
                    <Swiper
                        spaceBetween={0}
                        ref={swiperRefLocal}
                        slidesPerView={1}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 5000,
                            pauseOnMouseEnter: true
                        }}
                    >
                        {
                            prizesDraws?.map((prizes) => (
                                <SwiperSlide
                                    onClick={onPlayCarrousel}

                                    key={prizes.id}>
                                    <img src={prizes.banner} width="100%" alt="Banner" />
                                </SwiperSlide>


                            ))
                        }
                    </Swiper>
                </Grid>
                <Typography variant="h2" textAlign={"center"} fontWeight={"400"}>
                    <strong>Cliente</strong>: <i>{user?.name?.split(" ")[0]}</i>
                </Typography>
                <Button
                    component="a"
                    href={sendToContests()}
                    variant="contained"
                    sx={{
                        animation: 'pulseWithoutBoxShadow 1s infinite',
                        fontWeight: '700',
                        fontSize: 20,
                        padding: '20px 0'
                    }}
                    fullWidth>
                    APOSTAR AGORA
                </Button>
                <div>
                    <Typography variant="h4" fontWeight={"400"} textAlign={"center"}>
                        Saldo Disponível:
                    </Typography>
                    <Typography fontWeight="bold" color={palette.primary.main} variant="h1" textAlign={"center"}>
                        {user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </Typography>
                </div>

                <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                        animation: 'pulseWithoutBoxShadow 2s infinite'
                    }}
                    onClick={() => navigate(RoutesPath.Clients.deposito.path)}
                    startIcon={<div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <Typography fontSize={20}>+</Typography>
                        <RiMoneyDollarCircleLine style={{
                            marginLeft: 2
                        }} />
                    </div>}
                >
                    ADICIONAR SALDO PARA APOSTAS
                </Button>
                {
                    (user?.credits ?? 0) > 0 && (user?.credits ?? 0) <= constants.minPixTransfer ?
                        <Typography component="i" color="GrayText" textAlign="center" fontSize={'.8rem'}>
                            Saque disponível apenas para valores acima de <br />R$ 7,50.
                            (Conforme o <Typography onClick={() => {
                                navigate("/regulamento", {
                                    state: {
                                        prev_path: window.location.pathname
                                    }
                                })
                            }} component="a" href="/regulamento" color="inherit" fontSize={'.8rem'}>regulamento</Typography>)
                        </Typography>

                        : <></>
                }
                <Button
                    onClick={() => navigate(RoutesPath.Clients.saque.path)}
                    variant="outlined"
                    disabled={user?.credits ? user?.credits < constants.minPixTransfer : true}
                    fullWidth
                    startIcon={<BiMoneyWithdraw />}
                >
                    SOLICITAR SAQUE
                </Button>
                <Grid container justifyContent={'space-between'} alignItems="center">
                </Grid>
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<MdFullscreen size={30} />} variant="outlined" color="inherit" fullWidth onClick={() => setOpenDetails(true)}>
                    Abrir detalhes do meu saldo
                </Button>
                <Divider style={{ borderColor: 'black', borderBottomWidth: 'thin', margin: '20px 0px' }} />
                <div>
                    <Typography variant="h5" fontWeight={"400"} textAlign={"center"}>
                        Bônus de aposta:
                    </Typography>
                    <Typography fontWeight="bold" color={palette.secondary.main} variant="h2" textAlign={"center"}>
                        {(user?.guessCredits ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </Typography>
                    <Typography color="GrayText" textAlign="center" fontSize={'.8rem'}>(Valor disponível apenas para compra de apostas)</Typography>
                </div>

                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={loadingGuessCredit ? <CircularProgress size={24} /> : <MdFullscreen size={30} />} variant="outlined" color="inherit" fullWidth onClick={() => getAllMovements(true)}>
                    Abrir detalhes do meu saldo de bônus
                </Button>



            </Grid>
            <Divider style={{ borderColor: 'black', borderBottomWidth: 'thin' }} />
            <Typography variant="h2" fontWeight={"bold"} textAlign={"center"}>
                Meus concursos
            </Typography>
            <Grid container alignItems="center" gap={2} flexDirection={'column'}>
                <Menu
                    open={anchorElTickets}
                    handleOpen={() => setAnchorElTickets(true)}
                    title="Ver meus bilhetes"
                    handleClose={handleCloseTickets}
                >
                    {
                        drawParticipation?.map(({ id, prizeDrawAt }) =>
                            <MenuItem onClick={() => {
                                navigate(RoutesPath.Clients.bilhetes.path + `/${id}`)
                                handleCloseTickets();
                            }}>
                                Concurso {id} {String(moment(prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(prizeDrawAt).format("L")).toUpperCase()} às {String(moment(prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                            </MenuItem>
                        )
                    }
                </Menu>
                <Menu
                    open={anchorElResults}
                    handleOpen={() => setAnchorElResults(true)}
                    title="Ver meus resultados"
                    handleClose={handleCloseResults}
                >
                    {
                        (!prizeDraws || prizeDraws?.length === 0) &&
                        <MenuItem onClick={() => {
                            handleCloseResults();
                        }}>
                            Não há resultados
                        </MenuItem>
                    }
                    {
                        prizeDraws?.sort((a, b) => b.prizeDrawId - a.prizeDrawId).map((data) => (
                            <MenuItem key={data?.id} onClick={() => {
                                handleCloseResults();
                                setPrizeDrawsResult(data);
                            }}>
                                {String(moment(data?.prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(data?.prizeDrawAt).format("L")).toUpperCase()} às {String(moment(data?.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                            </MenuItem>
                        ))
                    }
                </Menu>
                <Button

                    onClick={() => setShowPrize(true)}
                    startIcon={<MdFullscreen size={30} />} variant="outlined" color="inherit" fullWidth sx={{ justifyContent: 'flex-start' }}>
                    Ver meus prêmios
                </Button>
                <Button
                    variant="text"
                    sx={{
                        color: "GrayText"
                    }}
                    // fullWidth
                    onClick={AuthService.logout}
                >Sair</Button>
            </Grid>
            <TableDetailsWithdrawView
                open={openDetails}
                handleClose={() => {
                    setOpenDetails(false)
                    getAllMovements()
                    queryParams.delete("movements")
                    navigate(`${location.pathname}?${queryParams.toString()}`);
                }}
                historic={historic}
            />
            <TablePrizes
                open={showPrizes}
                handleClose={() => setShowPrize(false)}
                prizes={prizes?.sort((a, b) => b.prizeDrawId - a.prizeDrawId) as PrizesProps[]}
            />
            <TableResults
                open={!!prizeDrawsResult}
                handleClose={() => setPrizeDrawsResult(null)}
                prizeDraw={prizeDrawsResult}
            />
        </Grid>
    )
}

export default ClientApp;
import { LPNav } from '../../../layout/lp';

import style from './styles.module.css'
import { RodapeLP } from 'layout/lp/rodape';
import ComissionIMG from 'assets/imgs/lp/correspondent/Comission.svg'
import ComissionMobileIMG from 'assets/imgs/lp/correspondent/Commission mobile.svg'

import ButtonIMG from 'assets/imgs/lp/correspondent/Button.svg'
import { useMediaQuery, useTheme } from '@mui/material';
import useQuery from 'hooks/useQuery';
import { Link, useNavigate } from 'react-router-dom';
import Arrow from 'assets/imgs/lp/contest/arrow.png'
import NewFavicon from 'assets/new favicon.png'
import Actor from 'assets/imgs/lp/correspondent/ATOR.png'
import Commission from 'assets/imgs/lp/correspondent/35.png'



export function LPCorrespondent() {
    const media = useMediaQuery('(min-width:1744px)')
    const theme = useTheme()
    const query = useQuery()
    const navigate = useNavigate()

    const onClick = (): string => {
        let _query = ""
        let correspondent = query.get('s')
        let general = query.get('g')

        if (correspondent) {
            _query += `?s=${correspondent}`

        }
        if (general) {
            _query += `?g=${general}`

        }
        let url = ("/correspondente/cadastro" + _query)
        return url
    }

    function getHighlightedText(text: string, highlight: string) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <h2 style={{
            color: "#666666",
            fontWeight: 400,
            width: '90%',
            textAlign: 'center',
            marginTop: 40
        }}> {parts.map((part, i) =>
            <span className={style.underline} key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { textDecoration: 'underline', textDecorationColor: theme.palette.primary.main, color: theme.palette.primary.main } : {}}>
                {part}
            </span>)
            } </h2>;
    }

    return (
        <div className="containerLP">
            <LPNav />
            <div className={style.container}>
                <div>
                    <div>
                        <img src={Actor} alt="Ator" />
                        {/* <Link to={onClick()}>
                            <img src={Commission} alt="imagem de 35% de comissão" />
                        </Link> */}

                    </div>
                </div>
                <div className={style.contentRight}>
                    <div>
                        <Link to={onClick()}>
                            <img src={ButtonIMG} alt="botão venha ser correspondente" />
                        </Link>
                    </div>
                </div>
            </div>
            <RodapeLP/>
        </div>
    )
}
import { useForm, SubmitHandler } from "react-hook-form"
import validator from "validator"
import { Button, FormControl, FormHelperText, Grid, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Typography, useTheme } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Validators } from "utils/validators";
import { CEPMaskCustom, CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom, PasswordMobileMaskCustom } from "components/mask";
import { InputsFormsRegister } from "types";
import { AuthService } from "service/auth";
import { LoadingButton } from "@mui/lab";
import { Moment } from "moment";
import useSocket from "service/socket";
import AlertAwaitIdentity from "./awaitIdentityConfirm";
import useAuth from "context/auth";
import useQuery from "hooks/useQuery";
import { toast } from "react-toastify";
import { WhatsApp } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const RegisterPage = () => {

    const query = useQuery();

    const { state } = useLocation()

    const { Authenticate } = useAuth();

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
        setError
    } = useForm<InputsFormsRegister>()

    const [phoneValidateRegister, setPhoneValidateRegister] = useState(false)
    const [phoneValidateRegisterError, setPhoneValidateRegisterError] = useState<boolean>(false)

    const [showBirthDate, setshowBirthDate] = useState(false)

    const [birthday, setBirthday] = useState<Moment | null>(null)

    const [type, setType] = useState<"pf" | "pj">("pf")

    const [loading, setLoading] = useState(false)

    const [invite, setInvite] = useState<string | null>(null)

    const theme = useTheme();

    const [userId, setUserId] = useState<number | null>(null)

    const handleSubmitRegister = (data: InputsFormsRegister) => {
        delete data.repeatPassword
        setLoading(true)
        const birthdaydate = birthday ? birthday.format("YYYY-MM-DD") : undefined

        const general = query.get("g")
        const body: InputsFormsRegister = {
            ...data,
            birthday: birthdaydate,
            ... (!general && {
                indicatedBy: invite ?? "DYNqidJ0DbXR"
            }),
            ... (general && {
                generalId: general
            })
        }
        AuthService.register(body)
            .then(({ data }) => {
                if (data?.login) {
                    Authenticate(data?.login, 'seller')
                    // setPhoneValidateRegister(true)
                    // setUserId(data?.id)
                }
            })
            .catch((err) => {
                if (err?.response?.data.point === "Verificação de CEP") {
                    setError("cep", {
                        message: "CEP não encontrado",
                    })
                }
                toast(err?.response?.data?.message ?? "Ocorreu um error ao se cadastrar!", {
                    type: 'error'
                })
                if (err?.response?.status === 404 || err?.response?.data?.message?.error === "access_denied") {
                    setshowBirthDate(true)
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const invite = query.get('s')
        setInvite(invite ?? null)
        if (state?.cpf) {
            setValue("cpf", state.cpf)
            if (state.cpf.length > 14) {
                setType("pj")
            }
        }

    }, [])

    return (
        <Grid mt={5} pb={3}>
            <Typography
                fontWeight="bold"
                variant="h2"
                textAlign="center">
                Cadastro
            </Typography>
            <Grid container mt={5} gap={2}>

                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Tipo de cadastro
                </Typography>
                <Grid container>
                    <Select fullWidth value={type} onChange={(e) => {
                        setType(e.target.value as any)
                        setValue("cpf", "")
                    }}>
                        <MenuItem value="pf">Pessoa Física</MenuItem>
                        <MenuItem value="pj">Pessoa Jurídica</MenuItem>
                    </Select>
                </Grid>

                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe seu {type === "pf" ? "CPF" : "CNPJ"}*
                </Typography>
                <Grid container>
                    <OutlinedInput
                        id={type === "pf" ? "cpf" : "cnpj"}
                        error={!!errors.cpf}
                        placeholder={type === "pf" ? "000.000.000-00" : "00.000.000/0000-00"}
                        inputComponent={type === "pf" ? CPFMaskCustom : CNPJMaskCustom}
                        fullWidth
                        {...register("cpf", {
                            required: true,
                            validate: (value) => {
                                if (!(type === "pf" ? Validators.validateCPF(value) : Validators.validateCNPJ(value))) {
                                    return `${type === "pf" ? "CPF" : "CNPJ"} inválido!`
                                }
                            }
                        })}
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.cpf?.message as string}</FormHelperText>
                    <Typography
                        mt={1}
                        color={theme.palette.grey[600]}
                        variant="subtitle2"
                    >
                        <li>
                            <i> Os pagamentos de suas comissões serão realizados através de PIX vinculado ao {type === "pf" ? "CPF" : "CNPJ"} acima. </i>
                        </li>
                    </Typography>
                </Grid>
                {
                    showBirthDate &&
                    <>
                        <Typography
                            fontWeight="bold"
                            variant="h5"
                        >
                            Informe sua data de Nascimento*
                        </Typography>
                        <DesktopDatePicker
                            format="DD/MM/YYYY"
                            autoFocus
                            value={birthday}
                            onChange={(event) => setBirthday(event)}
                        />
                    </>
                }
                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe seu WhatsApp
                </Typography>
                <Grid container>
                    <OutlinedInput
                        error={!!errors.phone}
                        placeholder="(00) 0 0000-0000"
                        inputComponent={CellphoneMaskCustom}
                        fullWidth
                        {...register("phone", {
                            required: true,
                            validate: (value) => {
                                if (!Validators.validateWhatsAppNumber(value)) {
                                    return "WhatsApp invalido!"
                                }
                            }
                        })}
                        startAdornment={
                            <InputAdornment position="start">
                                <WhatsApp color="success" />
                            </InputAdornment>
                        }
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.phone?.message as string}</FormHelperText>
                </Grid>
                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe seu CEP
                </Typography>
                <Grid container>
                    <OutlinedInput
                        error={!!errors.cep}
                        placeholder="00000-000"
                        fullWidth
                        {...register("cep", {
                            required: true,
                            validate: (value) => {
                                if (!validator.isPostalCode(value, 'BR')) {
                                    return "CEP invalido!"
                                }
                            }
                        })}
                        inputComponent={CEPMaskCustom}
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.cep?.message as string}</FormHelperText>
                </Grid>
                {/* <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Cadastre sua senha numérica com 4 dígitos
                </Typography>
                <Grid container>
                    <OutlinedInput
                        error={!!errors.password}
                        placeholder="••••"
                        fullWidth
                        {...register("password", {
                            required: true,
                            minLength: {
                                value: 4,
                                message: "Senha deve possuir 4 digitos"
                            },
                            maxLength: {
                                value: 4,
                                message: "Senha deve possuir 4 digitos"
                            }
                        })}
                        inputComponent={PasswordMobileMaskCustom}
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.password?.message as string}</FormHelperText>
                </Grid>

                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Confirme sua senha numérica com 4 dígitos
                </Typography>
                <Grid container>

                    <OutlinedInput
                        error={!!errors.repeatPassword}
                        placeholder="••••"
                        fullWidth
                        {...register("repeatPassword", {
                            required: true,
                            minLength: {
                                value: 4,
                                message: "Senha deve possuir 4 dígitos"
                            },
                            maxLength: {
                                value: 4,
                                message: "Senha deve possuir 4 dígitos"
                            },
                            validate: value => {
                                if (value !== getValues("password")) {
                                    return "Senhas devem ser iguais"
                                }
                            }
                        })}
                        inputComponent={PasswordMobileMaskCustom}
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.repeatPassword?.message as string}</FormHelperText>
                </Grid> */}
                <LoadingButton
                    loading={loading}
                    onClick={handleSubmit(handleSubmitRegister)}
                    sx={{ mt: 2 }}
                    variant="contained"
                    fullWidth
                    size="large">
                    Cadastrar
                </LoadingButton>
            </Grid>
            <AlertAwaitIdentity
                open={phoneValidateRegister}
                userId={userId}
                error={phoneValidateRegisterError} />
        </Grid>
    )
}

export default RegisterPage;
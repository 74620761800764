import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import RotateComponente from 'components/rotate';
import TableView from 'components/table';
import { ComissionReportProps, ResultsPrizesProps, prizeDrawsProps } from 'types';
import moment from 'moment';
import { NameMask, formatCPF, maskCPF } from 'utils/mask';
import { Link } from 'react-router-dom';
import RoutesPath from 'utils/routesBases';

interface TableCommissionProps {
    open: boolean,
    handleClose: () => void;
    historic: {
        balance: number, // SALDO
        creditValue: number, // VALOR
        event: number | string, // DESCRIÇÃO
        createdAt: Date | string, // DATA
        kind: string, // NATUREZA
        betUUID: string | null,
        clientCpf: string | null,
        clientName: string | null,
        eventNumber: number

    }[] | null;
}

export default function TableDetailsWithdraw({ open, handleClose, historic }: TableCommissionProps) {

    const theme = useTheme();

    const prize = ['Quadra', 'Terno', 'Duque', 'Unidade']

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0, overflowY: 'hidden' } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
                overflow: 'hidden'
                // overflow: 'hidden'
            }}>
                <RotateComponente>
                    <TableView
                        align='center'
                        header={['DATA', 'NATUREZA', 'DESCRIÇÃO', 'VALOR', 'SALDO']}
                    >
                        <TableBody>
                            {
                                historic?.map(({
                                    createdAt,
                                    event,
                                    balance,
                                    creditValue,
                                    kind,
                                    betUUID,
                                    eventNumber
                                }) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="center">
                                                {moment(createdAt).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {kind}
                                            </TableCell>
                                            <TableCell align="center" style={{
                                                fontWeight: 'bold'
                                            }}>
                                                {event}
                                                <br />
                                                {betUUID && eventNumber === 200 && <Link to={RoutesPath.Clients.bet.recibo.path + "/" + betUUID} style={{
                                                    fontSize: '10px',
                                                    fontWeight: '400'
                                                }}>
                                                    Bilhete: {betUUID}
                                                </Link>}
                                            </TableCell>
                                            <TableCell align="center" style={{ minWidth: 150, fontWeight: 'bold' }}>
                                                {creditValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                            <TableCell align="center" style={{ minWidth: 150 }}>
                                                {balance?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </TableView>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>

                </Button> */}
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import { Button, CircularProgress, Divider, FormControl, Grid, Menu, MenuItem, Select, Typography, useTheme } from "@mui/material"
import moment from "moment";
import { useEffect, useState } from "react";
import { PrizeDrawsService } from "service/prizes";
import { ResultsPrizesProps, prizeDrawsProps } from "types";
import TableResults from "../myPrizeDraws/results";

const ResultsPage = () => {

    const [prizeDrawsResult, setPrizeDrawsResult] = useState<null | prizeDrawsProps>(null);

    const [prizeDraws, setPrizeDraws] = useState<null | prizeDrawsProps[]>(null);

    const [loading, setLoading] = useState(false);

    const getPrizeDrawsResults = () => {
        setLoading(true)
        PrizeDrawsService.results()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setPrizeDraws(data.prizeDraws)
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getPrizeDrawsResults();
    }, [])

    return (
        <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'} alignItems="center">
            <Typography variant="h2" textAlign={"center"} fontWeight={"bold"}>
                Resultados
            </Typography>
            {
                !prizeDraws || prizeDraws?.length === 0 &&
                <Typography variant="h4" textAlign={"center"} fontWeight={"bold"}>
                    Sem resultados
                </Typography>
            }
            {
                loading ? 
                <CircularProgress />
                : prizeDraws?.map((data) => (
                    <Grid container>
                        <Button
                            fullWidth
                            sx={{ m: 0 }} key={data?.id} onClick={() => {
                                setPrizeDrawsResult(data);
                            }}>
                            {String(moment(data?.prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(data?.prizeDrawAt).format("L")).toUpperCase()} às {String(moment(data?.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                        </Button>
                        <Divider style={{ borderBottomWidth: 'thin' }} />
                    </Grid>
                ))
            }
            <TableResults
                open={!!prizeDrawsResult}
                handleClose={() => setPrizeDrawsResult(null)}
                prizeDraw={prizeDrawsResult}
            />
        </Grid>
    )
}
export default ResultsPage;
import { Button, CircularProgress, Divider, Grid, Typography, useTheme } from "@mui/material"
import useBet from "context/bet";
import { FiPrinter } from "react-icons/fi";

import printJS from "print-js";
import { IoCheckmarkCircle, IoLogoWhatsapp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RoutesPath from "utils/routesBases";
import { BetService } from "service/bets";
import { toast } from "react-toastify";

const ListVoucherPage = () => {
    const navigate = useNavigate();

    const { ResetBet } = useBet();

    const { id } = useParams();

    const [loading, setLoading] = useState(false)

    const [betsVouchers, setBetsVouchers] = useState<{
        certificateInfo: {
            html: string;
            pdf: string;
        }
    }[] | null>(null);

    useEffect(() => {
        ResetBet();
    }, [])

    useEffect(() => {

        if (id) {
            getTikets(Number(id))
        }
    }, [id])


    const getTikets = (prizeId: number) => {
        setLoading(true)
        BetService.getTicket(prizeId)
            .then(({
                data
            }) => {
                if (data?.bets) {
                    setBetsVouchers(data?.bets)
                }
            })
            .catch((err) => {
                toast(err?.response?.data?.message ?? "Não foi possível buscar bilhetes", {
                    type: 'error'
                })
             })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <Grid container gap={3}>
            {loading && <Grid container alignItems="center" justifyContent={"center"} minHeight={450}>
                <CircularProgress size={24}/>
            </Grid>
            }
            {
                betsVouchers?.map(({
                    certificateInfo
                }) => (
                    <Grid container gap={3}>
                        <iframe
                            style={{
                                border: 'none',
                                width: '100%',
                                height: '72rem',
                                marginTop: 25
                            }}
                            src={certificateInfo?.html}>
                        </iframe>
                        <Grid container alignItems={"center"} justifyContent={"center"} gap={2} py={2} flexDirection={'column'}>
                            {/* <Button onClick={() => printJS({
                                printable: certificateInfo?.pdf,
                                type: 'pdf',
                                showModal: false,

                            })} variant="outlined" startIcon={<FiPrinter />}>
                                Imprimir
                            </Button> */}
                            <Button
                                component="a"
                                target="_blank"
                                href={certificateInfo?.pdf}
                                variant="text"
                                fullWidth
                                sx={{ maxWidth: 200, p: 2, mt: 2 }}>
                                Baixar PDF
                            </Button>
                        </Grid>
                    </Grid>
                ))
            }
            <Grid p={2} container justifyContent="center">
                <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    fullWidth
                    sx={{ maxWidth: 200 }}>
                    Voltar
                </Button>
            </Grid>
        </Grid>
    )
}

export default ListVoucherPage;
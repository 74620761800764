import api from "service/api"

export class UtilsService {
    static async getDistricts(): Promise<{
        city: string,
        uf: {
            sigla: string,
            name: string,
        }
    }[]> {
        const array = []
        const data = await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/distritos').then(({data}) => data)
        for (let d of data) {
            array.push({
                city: d.nome,
                uf: {
                    sigla: d.municipio.microrregiao.mesorregiao.UF.sigla,
                    name: d.municipio.microrregiao.mesorregiao.UF.nome
                }
            })
        }
        return array
    }
}